import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import API from './Components/Global_API'
import Router from './Router';
import { Icon } from 'react-icons-kit'
import { trashO, plus } from 'react-icons-kit/fa'
import { search } from 'react-icons-kit/icomoon/search'
import { eye, cross, withLine } from 'react-icons-kit/entypo'
import { Button, InputGroup, FormControl, Accordion, Card, Modal } from 'react-bootstrap';
import Footer from './Components/Footer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    borderRadius: 3,
    display: 'flex',
    flexWrap: 'wrap'
});

const styles = {
    confirmButton: {
        backgroundColor: 'rgb(70, 180, 233)',
        borderColor: 'rgb(70, 180, 233)'
    }
}
export default class CoursesScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            course: [],
            noDataFound: false,
            text: '',
            shown: true,
            course_id: '',
            shownicon: false,
            filterTitle: '',
            allcourses: '',
            from_date: '',
            to_date: '',
            show: false,
            Item_for_delete: '',
            changeCourseStatus: '',
            PublishedCoursePopup: false,
            error_msg: '',
            errorPopup: false
        };
        this.handleClose = this.handleClose.bind(this);
    }

    addDefaultSrc(e) {
        e.target.src = require('../assets/pta-logo2.png');
        e.target.onerror = null;
    }

    handleShow(item) {
        this.setState({
            show: true,
            Item_for_delete: item
        });
    }

    handleClose() {
        this.setState({ show: false });
    }

    CourseDetails() {
        fetch(API + 'get_courses.php')
            .then(res => res.json())
            .then((data) => {

                if (data.result === 'success') {
                    this.setState({ course: data.course_data, allcourses: data.course_data, course_id: data.course_data.course_id, course_from_date: data.course_data.fromdate, course_to_date: data.course_data.todate, course_due_date: data.course_data.duedate })
                } else {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    x.innerHTML = data.msg;
                }

            }).catch((error) => {
                console.log(error.message);
            });
    }

    componentDidMount() {
        this.CourseDetails();
    }

    handleDeleteCourse() {
        var item = this.state.Item_for_delete;
        let course_id = JSON.stringify(
            {
                course_id: item.course_id
            });

        fetch(API + 'delete_course.php',
            {
                method: 'POST',
                body: course_id
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.setState({ show: false });
                    this.CourseDetails();
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    handleCreateCourse() {
        this.props.history.push('/Create_Course', { course: '' })
    }
    handleSubmit(course) {
        this.props.history.push('/Create_Course', { course: course })
    }

    handleCourseStatusPopupOpen(item) {
        this.setState({
            PublishedCoursePopup: true,
            changeCourseStatus: item
        });
    }

    handlePublishedCoursePopupClose = () => {
        this.setState({ PublishedCoursePopup: false });
    }

    handleCourseStatus() {
        var item = this.state.changeCourseStatus;
        if (item.status === 'Published') {
            item.status = "Unpublished"
        } else {
            item.status = "Published"
        }
        let data = JSON.stringify(
            {
                course_id: item.course_id,
                type: "course",
                text: item.status
            });

        fetch(API + 'published_data.php',
            {
                method: 'POST',
                body: data
            }).then((response) => response.json()).then((responseJsonFromServer) => {

                if (responseJsonFromServer.result === "success") {
                    this.state.course.map((c) => {
                        if (c.course_id === item.course_id) {
                            c.status = item.status;
                        }
                        return c;
                    })
                    this.setState({ shownicon: true, PublishedCoursePopup: false })

                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

                    x.innerHTML = responseJsonFromServer.msg;
                    this.CourseDetails();
                } else {

                    this.setState({ error_msg: responseJsonFromServer.msg, PublishedCoursePopup: false, errorPopup: true })
                    // y.innerHTML = responseJsonFromServer.msg;
                    this.CourseDetails();
                }

            }).catch((error) => {
                console.warn(error.message);
            });
    }

    handleClearFilter() {
        this.setState({ filterTitle: '', from_date: '', to_date: '' });
        this.setState({ course: this.state.allcourses });
    }

    handleFromDateFilter = (date) => {
        this.setState({ from_date: date });
        if (date) {
            let filterdCourses = this.state.course.filter((c) => {
                return c.fromdate === (date.toLocaleDateString("en-US"));
            });

            this.setState({ course: filterdCourses });
            if (filterdCourses.length > 0) {
                this.setState({ noDataFound: false });

            } else {
                this.setState({ noDataFound: true })
            }
        } else {
            this.setState({ course: this.state.allcourses });
        }
    }

    handleToDateFilter = (date) => {

        this.setState({ to_date: date });
        if (date) {
            let filterdCourses = this.state.course.filter((c) => {
                return c.todate === (date.toLocaleDateString("en-US"));
            });
            this.setState({ course: filterdCourses });
            if (filterdCourses.length > 0) {
                this.setState({ noDataFound: false });
            } else {
                this.setState({ noDataFound: true })
            }
        } else {
            this.setState({ course: this.state.allcourses });
        }
    }

    handleTitleFilter(e) {
        this.setState({ filterTitle: e.target.value });
        if (this.state.filterTitle.length >= 1) {
            let course_data = this.state.allcourses
            let filterdCourses = course_data.filter((c) => {
                return (c.course_name.toLowerCase()).includes(e.target.value.toLowerCase());
            });

            this.setState({ course: filterdCourses });
            if (filterdCourses.length > 0) {
                this.setState({ noDataFound: false });
            } else {
                this.setState({ noDataFound: true })
            }
        } else {
            this.setState({ course: this.state.allcourses });
        }
    }


    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {

        if (!result.destination) {
            return;
        }

        const course = this.reorder(
            this.state.course,
            result.source.index,
            result.destination.index
        );

        this.setState({
            course
        });

        let course_sequence = []

        course.map((item, index) =>
            course_sequence.push({ course_id: item.course_id, sequence_id: index })
        )

        let course_data = JSON.stringify({
            sequence_data: course_sequence
        })



        fetch(API + 'drag_courses.php',
            {
                method: 'POST',

                body: course_data
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                console.warn(error.message);
            });
    };


    renderCourse() {
        if (this.state.course.length) {
            return (
                <div className="course-items-container">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(droppableProvided, droppableSnapshot) => (
                                <div
                                    className="col-12"
                                    ref={droppableProvided.innerRef}
                                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                                >
                                    {this.state.course.map((item, index) => (
                                        <div className="col-lg-4 col-md-6 col-xs-12" key={item.course_id}>
                                            <Draggable key={item.course_id} draggableId={item.course_id} index={index}>
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <div
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getItemStyle(
                                                            draggableSnapshot.isDragging,
                                                            draggableProvided.draggableProps.style
                                                        )}
                                                    >

                                                        <div className="course-card-item" key={item.course_id}>
                                                            {/* <div className="course-card-item col-lg-4 col-md-6 col-xs-12" key={item.course_id}> */}
                                                            <div className="react-card">
                                                                <a onClick={() => this.handleSubmit(item)} style={{ color: '#737373', textDecoration: 'none' }}>
                                                                    <div className="card-content">
                                                                        <div className="card-thumb">
                                                                            <img src={item.image} onError={this.addDefaultSrc} className="course-image" alt="course" />
                                                                        </div>
                                                                        <div className="card-text">
                                                                            <h5 style={{ fontWeight: '600', fontSize: 17, color: '#000000', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.course_name}</h5>
                                                                            <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', overflowWrap: 'anywhere' }}>{item.description}</p>

                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-actions">
                                                                    <div className="CourseCardActionsContainer-sc-xm0i4x hHdVrU">
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div className="btn icon-btn" onClick={() => this.handleShow(item)}>
                                                                                <Icon size={20} icon={trashO} />
                                                                            </div>
                                                                            <div className="btn" onClick={() => this.handleCourseStatusPopupOpen(item)}>
                                                                                <label class="switch">
                                                                                    <input type="checkbox" checked={item.status === "Published" ? true : false} onClick={() => this.handleCourseStatusPopupOpen(item)} />
                                                                                    <span class="slider round"></span>
                                                                                </label>

                                                                            &nbsp; <span style={{ fontWeight: '600' }}>{item.status}</span>

                                                                            </div>
                                                                        </div>
                                                                        <div className="btn" style={{ fontWeight: '600', paddingLeft: 0 }} onClick={() => this.handleSubmit(item)}>{item.unit_count} Unit</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>


                    <Modal size="sm" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Body>Are you sure you want to delete this course?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>No</Button>
                            <Button style={styles.confirmButton} onClick={() => this.handleDeleteCourse()}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="sm" show={this.state.PublishedCoursePopup} onHide={this.handlePublishedCoursePopupClose}>
                        <Modal.Body>Are you sure you want to {this.state.changeCourseStatus.status == 'Published' ? 'Unpublished' : 'Published'} this course?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handlePublishedCoursePopupClose}>No</Button>
                            <Button style={styles.confirmButton} onClick={() => this.handleCourseStatus()}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="sm" show={this.state.errorPopup} onHide={() => this.setState({ errorPopup: false })}>
                        <Modal.Body>{this.state.error_msg}</Modal.Body>
                        <Modal.Footer>
                            <Button style={styles.confirmButton} block onClick={() => this.setState({ errorPopup: false })}>Ok</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        } else if (this.state.noDataFound) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 30 }}>
                    <div class="placeholder-card" style={{ maxWidth: 480 }}>Sorry, no courses were found matching your search parameters.</div>
                </div>
            );
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 30 }}>
                    <img src={require('../assets/Loader.gif')} alt="loading.." />
                </div>
            );
        }
    }

    render() {
        return (
            <div style={{ minHeight: '100vh', overflow: 'hidden' }}>
                <Router />
                <div style={{ backgroundColor: '#dfe2e6' }}>
                    <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60, minHeight: '15vh' }}>
                        <div className="row" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingBottom: 10 }}>
                            <Accordion style={{ width: '100%' }}>
                                <div className="row">
                                    <div className="col-md-7 col-sm-5 block-v-sm">
                                        <label style={{ fontSize: 25, paddingLeft: 30, fontWeight: '600' }}>Courses</label>
                                    </div>
                                    <div className="col-md-5 col-sm-7 text-right block-v-sm" style={{ paddingTop: 5, paddingRight: 30 }}>
                                        <Accordion.Toggle as={Button} style={{ backgroundColor: '#ebeced', marginRight: 10, fontWeight: 'bold' }} variant="default" eventKey="0">
                                            <Icon size={13} icon={search} style={{ paddingRight: 5 }} /> Filter
                                            </Accordion.Toggle>
                                        <a onClick={() => this.handleCreateCourse()} className="btn btn-default" style={{ backgroundColor: '#46b4e9', color: '#fff', fontWeight: 'bold' }}><Icon size={20} icon={plus} /> Create a courese</a>
                                    </div>
                                </div>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body >
                                        <div style={{ marginTop: -20 }}>
                                            <hr />
                                        </div>

                                        <div className="course-filter-container">
                                            <div className="course-nameFilter">
                                                <InputGroup className="mb-3" style={{ paddingRight: 10 }}>
                                                    <FormControl value={this.state.filterTitle} type="text" placeholder="Course Title" maxLength={30} onInput={this.handleTitleFilter.bind(this)} />
                                                </InputGroup>
                                            </div>
                                            <div className="course-dateFilter">
                                                <InputGroup className="mb-3 mr-2">
                                                    <DatePicker
                                                        className="form-control"
                                                        placeholderText="From Date"
                                                        selected={this.state.from_date}
                                                        onChange={this.handleFromDateFilter}
                                                        maxDate={this.state.to_date}
                                                        showTimeSelect={false}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={false}
                                                        showTimeInput={false}
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="course-dateFilter">
                                                <InputGroup className="mb-3" style={{ paddingRight: 10 }}>
                                                    <DatePicker
                                                        className="form-control"
                                                        placeholderText="To Date"
                                                        selected={this.state.to_date}
                                                        onChange={this.handleToDateFilter}
                                                        minDate={this.state.from_date}
                                                        showTimeSelect={false}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={false}
                                                        showTimeInput={false}
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="course-clearFilter">
                                                <Button variant="defualt" onClick={this.handleClearFilter.bind(this)} style={{ backgroundColor: '#dfe2e6', justifyContent: 'center' }} className="mb-3"><Icon size={20} icon={cross} style={{ paddingRight: 5 }} /></Button>
                                            </div>


                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Accordion>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div style={{ minHeight: '65vh' }}>
                            {this.renderCourse()}
                            <div id="snackbar"></div>
                            <div id="login"></div>
                        </div>
                    </div>
                    <div style={{ height: 190, backgroundColor: 'rgb(230, 235, 240)', zIndex: 10 }}>
                        <Footer />
                    </div>

                </div>
            </div >
        )
    }
}