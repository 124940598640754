import React from 'react';
import Navigation from './Navigation';
import Exercise from './Screens/Assessment_form/Exercise';
 
function App() {
  return (
      <Navigation />
  );
}

export default App;
