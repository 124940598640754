import React from 'react';
import Router from '../Router';
import Footer from '../Components/Footer';
import { Button } from 'react-bootstrap'
import API from '../Components/Global_API'

export default class Account_details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUser: JSON.parse(localStorage.getItem('loginUser')),
            first_name: '',
            mobile_number: '',
            userId: '',
            last_name:''
        }
        this.state.userId = this.state.loginUser.userId
        this.state.first_name = this.state.loginUser.first_name;
        this.state.last_name = this.state.loginUser.last_name;
        this.state.mobile_number = this.state.loginUser.mobile_number;
    }

    press = (e) => {
        if (e.ctrlKey || e.altKey) {
            e.preventDefault();
        } else {
            var key = e.keyCode;
            if (!((key === 8) || (key === 9) || (key === 46) || (key >= 35 && key <= 40) || (key >= 65 && key <= 90))) {
                e.preventDefault();
            }
        }
    }

    number_press = (event) => {
        if (!(event.ctrlKey || event.altKey
            || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false)
            || (95 < event.keyCode && event.keyCode < 106)
            || (event.keyCode === 8) || (event.keyCode === 9)
            || (event.keyCode > 34 && event.keyCode < 40)
            || (event.keyCode === 46))) {
            event.preventDefault();
        }
    }


    handleFirstName = (e) => {
        this.setState({ first_name: e.target.value })
    }

    handleLastName = (e) => {
        this.setState({ last_name: e.target.value })
    }

    handleMobile_number = (e) => {
        this.setState({ mobile_number: e.target.value })
    }

    handleSubmit = (e) => {
        
        let x = document.getElementById("login");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

        let updateProfile = JSON.stringify(
            {
                id: this.state.userId,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                mobile_number: this.state.mobile_number
            });

        fetch(API + 'update_user_details.php',
            {
                method: 'POST',
                body: updateProfile
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                if (responseJsonFromServer.result === 'success') {
                    localStorage.setItem("loginUser", JSON.stringify({ 'userId': responseJsonFromServer.id, 'email': this.state.loginUser.email_id, 'name': this.state.loginUser.user_name, 'mobile_number': responseJsonFromServer.mobile_number, 'first_name': responseJsonFromServer.first_name, 'last_name': responseJsonFromServer.last_name }))
                    x.innerHTML = responseJsonFromServer.msg;
                } else {
                    e.preventDefault();
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    render() {
        return (
            <div>
                <Router />
                <div style={{ backgroundColor: '#EEF2F6' }}>
                    <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingLeft: 20, paddingTop: 60, paddingRight: 20, paddingBottom: 20 }}>
                        <div className="pt-3">
                            <h4>Account Details</h4>
                        </div>
                    </div>
                    <div className="body-container" style={{ padding: 10, minHeight: 550 }}>
                        <div className="col-6">
                        <form onSubmit={this.handleSubmit}>
                            <div className="col-12">
                                <label style={{ fontWeight: '600' }}>Your Account</label>
                                <input type="text" disabled={true} style={{cursor: 'not-allowed'}} placeholder="Your Account" value={this.state.loginUser.email} class="form-control" />
                            </div>
                            <div className="col-12" style={{ paddingTop: 20 }}>
                                <label style={{ fontWeight: '600' }}>First Name</label>
                                <input type="text" placeholder="First Name" value={this.state.first_name} maxLength={30} minLength={2} class="form-control" onChange={this.handleFirstName} onKeyDown={this.press} />
                            </div>
                            <div className="col-12" style={{ paddingTop: 20 }}>
                                <label style={{ fontWeight: '600' }}>Last Name</label>
                                <input type="text" placeholder="Last Name" value={this.state.last_name} maxLength={30} minLength={2} class="form-control" onChange={this.handleLastName} onKeyDown={this.press} />
                            </div>
                            <div className="col-12" style={{ paddingTop: 20 }}>
                                <label style={{ fontWeight: '600' }}>Mobile Number</label>
                                <input type="text" placeholder="Mobile Number" value={this.state.mobile_number} maxLength={14} minLength={8} class="form-control" onChange={this.handleMobile_number} onKeyDown={this.number_press} />
                            </div>
                            <div className="col-12" style={{ paddingTop: 20 }}>
                                <Button type="submit" style={{ backgroundColor: '#42cbf5', borderColor: '#42cbf5' }} block>SAVE</Button>
                            </div>
                            </form>
                            <div id="login"></div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}