import React from 'react'
import { Icon } from 'react-icons-kit'
import { record } from 'react-icons-kit/iconic/record'

export default class ListSlide_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.data.title,
            // done_button_state: true,
            // answer_popup: true,
            selected_value: '',
            answer_reinforcement: '',
            list: this.props.data.list_slide_options
        }
        
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    render() {
        return (
            <div>
                <div style={{ height: 340, overflowY: 'auto' }}>
                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 30 }}>
                        <h5 className="text-center" style={{ color: '#fff', paddingBottom: 10, overflowWrap: 'anywhere' }}> {this.state.title}</h5>
                        {this.state.list.map((item, i) => (
                            <div style={styles.radio} className="text-left" key={item.list_option_id}>
                                <label style={{ width: '100%' }} >
                                    <Icon size={10} icon={record} style={{ paddingRight: 10 }} />
                                    {item.list_option}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        {/* <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}> */}
                        <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={styles.done_btn}>
                            Continue
                        </button>
                    </div>
                   
                </div>
            </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    radio: {
        borderWidth: 1,
        color: '#fff',
        margin: 5,
        paddingLeft: 20,
        borderRadius: 5,
        fontSize: 15,
        paddingTop: 5
    },
    continue_button: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.2)',
        padding: 10,
        backgroundColor: '#fff',
        color: '#000000'
    }
}