import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from 'react-icons-kit'
import { trashO, plus } from 'react-icons-kit/fa'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import { Accordion, Card, Button, Media } from 'react-bootstrap'
import Circle_the_answer from './Circle_the_answer';
import API from '../Components/Global_API'
import "./Circle_the_answer.css";

var mouse = { x: 0, y: 0 };
var last_mouse = { x: 0, y: 0 };

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff'
    },
    radio: {
        borderWidth: 1,
        backgroundColor: '#fff',
        margin: 5,
        paddingLeft: 20,
        borderRadius: 5,
        fontSize: 15,
        paddingTop: 5
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}

export default class Circle_Functionlity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.data.title,
            correctAnswer: this.props.data.answer_text,
            correct_reinforcement: this.props.data.correct_reinforcement,
            incorrect_reinforcement: this.props.data.incorrect_reinforcement,
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            circle_data: this.props.data.circle_the_answer_options,
            lessonId: this.props.data.lesson_id,
            prev_review: true,
            content_validation: false,
            PublishedSectionPopup: false,
            nextButton: false,
            prevButton: false,
            current_index: this.props.current_index,
            course_image: this.props.course_image,
            question_id: this.props.data.question_id,
            option_validation: false,



            options: [{
                text: "Answer 1",
                x: 55,
                y: 50,
              },
              {
                text: "Answer 2",
                x: 55,
                y: 100,
              },
              {
                text: "Answer 3",
                x: 55,
                y: 150,
              }],
              traversedX: [],
              traversedY: []
        }
        this.done = this.done.bind(this);
        this.Add = this.Add.bind(this);
        this.Remove = this.Remove.bind(this);

    }




    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 100) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 100 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    contentValidation = (i, e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_content' + i)[0].innerHTML = "Content is required.";
            document.getElementsByClassName('error_content' + i)[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ option_validation: true })
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_content' + i)[0].innerHTML = 'Content must be between 2 to 100 character.';
            document.getElementsByClassName('error_content' + i)[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ option_validation: true })
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            this.setState({ option_validation: false })
            document.getElementsByClassName('error_content' + i)[0].innerHTML = "";
            document.getElementsByClassName('error_content' + i)[0].style.display = 'none';
        }
    }

    correctAnswerValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_correct_answer')[0].innerHTML = "Correct answer is required.";
            document.getElementsByClassName('error_correct_answer')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_correct_answer')[0].innerHTML = "";
            document.getElementsByClassName('error_correct_answer')[0].style.display = 'none';
        }
    }

    correctReinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "Correct reinforcement is required.";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = 'Correct reinforcement must be between 2 to 100 character.';
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'none';
        }
    }

    incorrectReinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "Incorrect reinforcemen is required.";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = 'Incorrect reinforcemen must be between 2 to 100 character.';
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    doneTextValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {

            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }


    handleSubmit = (e) => {
        e.preventDefault();


        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 100) {
            document.getElementById("title").focus();
        } else if (this.state.option_validation) {
            console.log("not valid");
        } else if (this.state.correctAnswer.trim().length < 2) {
            document.getElementById("correct_answer").focus();
        } else if (this.state.correct_reinforcement.trim().length < 2 || this.state.correct_reinforcement.trim().length > 50) {
            document.getElementById("correct_reinforcement").focus();
        } else if (this.state.incorrect_reinforcement.trim().length < 2 || this.state.incorrect_reinforcement.trim().length > 30) {
            document.getElementById("incorrect_reinforcement").focus();
        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 30) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {

            var circle_option_data = []
            this.state.circle_data.map(item =>
                circle_option_data.push({ options: item.options })
            )

            let circle = JSON.stringify(
                {
                    lesson_id: this.state.lessonId,
                    circle_the_answer_id: this.state.question_id,
                    title: this.state.title,
                    prompt: this.state.prompt,
                    done_text: this.state.done_text,
                    answer: this.state.correctAnswer,
                    correct_reinforcement: this.state.correctAns,
                    incorrect_reinforcement: this.state.incorrectAns,
                    options_data: circle_option_data
                });

            fetch(API + 'edit_circle_the_ans.php',
                {
                    method: 'POST',

                    body: circle
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        this.props.handleUpdateQuetionData();

                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    console.warn(error.message);
                });
        }
    }


    handleChangeTitle = (e) => {

        this.setState({ title: e.target.value })
    }

    handleChangeSubTitle = (e) => {
        this.setState({ subtitle: e.target.value })
    }

    handleChangeContent = async (i, e) => {

        let value = e.target.value;
        let data = this.state.circle_data;
        data.map((item, index) => {
            if (index == i) {
                item.options = value;

            }
        })
        await this.setState({ circle_data: data });
    }

    handleSelectedAns = (e) => {
        this.setState({ selectedAns: e.target.value });
    }

    handleChangecorrectAnswer = (e) => {
        this.setState({ correctAnswer: e.target.value });
    }

    handleChangeCorrectAns = (e) => {
        this.setState({ correct_reinforcement: e.target.value });
    }

    handleChangeIncorrectAns = (e) => {
        this.setState({ incorrect_reinforcement: e.target.value });
    }

    handleChangeCoreMsg = (e) => {
        this.setState({ coreMsg: e.target.value })
    }

    handleChangeTime = (e) => {
        this.setState({ time: e.target.value });
    }

    handleChangeOut_time_prompt = (e) => {
        this.setState({ out_time_prompt: e.target.value });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value })
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    Add() {
        let add = this.state.circle_data;
        if (this.state.circle_data.length < 6) {
            let newu = { options: '' }

            add.push(newu)
            this.setState({
                circle_data: add,
            })
        }
    }

    Remove = async (i, e) => {
        let data = this.state.circle_data;
        if (this.state.circle_data.length > 1) {
            data.splice(i, 1);
            await this.setState({ circle_data: data });
        }
    }









    done() {
        let sX = Math.min(...this.state.traversedX);
        let lX = Math.max(...this.state.traversedX);
        let sY = Math.min(...this.state.traversedY);
        let lY = Math.max(...this.state.traversedY);
    
        let selectedAns = this.state.options.map((opt) => {
    
          if (opt.x > sX && opt.x < lX && opt.y > sY && opt.y < lY) {
            return opt;
          }
        })
        var canvas = document.querySelector('#paint');
        var ctx = canvas.getContext('2d');
    
    
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.setState({ traversedX: [], traversedY: [] });
        this.state.options.forEach(option => {
          ctx.fillText(option.text, option.x, option.y);
        });
    
      }
      componentDidMount() {
        var canvas = document.querySelector('#paint');
        var ctx = canvas.getContext('2d');
    
        var sketch = document.querySelector('#sketch');
        var sketch_style = getComputedStyle(sketch);
        canvas.width = 200;
        canvas.height = 200;
    
    
    
        /* Mouse Capturing Work */
        canvas.addEventListener('mousemove', function (e) {
    
          last_mouse.x = mouse.x;
          last_mouse.y = mouse.y;
    
          mouse.x = e.pageX - this.offsetLeft;
          mouse.y = e.pageY - this.offsetTop;
        }, false);
    
    
        /* Drawing on Paint App */
        ctx.lineWidth = 1;
        ctx.lineJoin = 'round';
        ctx.lineCap = 'round';
        ctx.strokeStyle = 'blue';
        ctx.font = "18px Verdana";
        ctx.fillStyle = "#fff"
    
        canvas.addEventListener('mousedown', function (e) {
          canvas.addEventListener('mousemove', onPaint, false);
        }, false);
    
        canvas.addEventListener('mouseup', function () {
          canvas.removeEventListener('mousemove', onPaint, false);
        }, false);
        let __this = this;
        var onPaint = function () {
          __this.state.traversedX.push(last_mouse.x);
          __this.state.traversedY.push(last_mouse.y);
    
    
          ctx.beginPath();
          ctx.moveTo(last_mouse.x, last_mouse.y);
          ctx.lineTo(mouse.x, mouse.y);
          ctx.closePath();
          ctx.stroke();
        };
        this.state.options.forEach(option => {
          ctx.fillText(option.text, option.x, option.y);
        });
    
    
      }
      paint() {
    
      }













    render() {
        return (
            <div className="row text-center">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="card card-sm">
                        <div className="card-content block-xl"  >
                            <div className="body-container" style={styles.body}>
                                <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                        <div className="row text-center">
                                            <div style={{ width: '10%', paddingLeft: 5 }}>
                                                <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                            </div>
                                            <div style={{ paddingTop: 15, width: '60%' }}>
                                                <hr color='#fff' />
                                            </div>
                                            <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                <div style={styles.dot_btn}>
                                                    <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                    <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ height: 380, fontSize: 20 }}>


{/* circle the answer code start */}

                                            <div className="draw-container">

                                                <div id="sketch">
                                                    <canvas id="paint"></canvas>

                                                </div>
                                                <button onClick={this.done}>I'mDone</button>
                                            </div>
{/* circle the answer code end */}


                                        </div>
                                    </div>
                                    <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                        <div>
                                            <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                <div className="text-center">
                                                    <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                        Review last slide
                                                </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                        Restart lesson
                                                        </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                        Cancel
                                                        </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                            <div className="col-4">
                                <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                            </div>
                            <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                <span >Slide {this.props.current_index + 1}</span>
                            </div>
                            <div className="col-4">
                                <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                    <div className="card-sm" >
                        {/* <form onSubmit={this.handleSubmit}> */}
                        <div className="card-content block-xl" style={{ height: 550, overflowY: 'scroll', overflowX: 'scroll' }}>
                            <h5 className="text-left" style={{ paddingBottom: 10 }}>Circle the Answer</h5>
                            <div className="text-left">
                                <div className="form-group">
                                    <div className="input-group-prepend">
                                        <text className="input-group-text" >Title</text>
                                    </div>
                                    <input type="text" className="form-control" id="title" minLength={2} maxLength={100} value={this.state.title} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                    <label className={"input-error error_title"}></label>
                                </div>
                                <hr />
                                <div>
                                    <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                        <div>
                                            <div eventKey="0">
                                                <h5>Answers</h5>
                                            </div>
                                            <Accordion.Collapse eventKey="0">
                                                <div>
                                                    {this.state.circle_data.map((item, i) => (
                                                        <div key={i} style={{ padding: 10, marginBottom: 10, backgroundColor: '#fff' }}>
                                                            <div className="form-group">
                                                                <div className="input-group-prepend">
                                                                    <text className="input-group-text" >Content</text>
                                                                </div>
                                                                <input type="text" className="form-control" id={"content" + i} minLength={2} maxLength={100} value={item.options} onChange={this.handleChangeContent.bind(this, i)} onBlur={this.contentValidation.bind(this, i)} />
                                                                <label className={"input-error error_content" + i}></label>
                                                            </div>
                                                            <div>
                                                                <Button variant="default" disabled={this.state.circle_data.length == 2} style={{ backgroundColor: '#E6EBF0' }} block onClick={this.Remove.bind(this, i)}>
                                                                    <Icon size={20} icon={trashO} style={{ paddingRight: 10 }} />
                                                                            Remove this section
                                                                        </Button>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div style={{ marginTop: 10, color: '#fff' }}>
                                                        <Button variant="default" disabled={this.state.circle_data == 6} onClick={this.Add.bind(this)} style={{ backgroundColor: '#223042', color: '#fff' }} block >
                                                            <Icon size={20} icon={plus} style={{ paddingRight: 10, color: '#fff' }} />
                                                                    Add a section
                                                                </Button>
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </div>
                                    </Accordion>
                                </div>
                                <hr />
                                <div>
                                    <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                        <div>
                                            <div as={Card.Body} eventKey="0">
                                                <h5>Answer</h5>
                                            </div>
                                            <Accordion.Collapse eventKey="0">
                                                <div>
                                                    <div className="form-group">
                                                        <div className="input-group-prepend">
                                                            <text className="input-group-text" >Correct Answer</text>
                                                        </div>
                                                        <select className="form-control" value={this.state.correctAnswer} id="correct_answer" onChange={this.handleChangecorrectAnswer} onBlur={this.correctAnswerValidation}>
                                                            {this.state.circle_data.map(item =>
                                                                <option value={item.options}>{item.options}</option>
                                                            )}

                                                        </select>
                                                        <label className={"input-error error_correct_answer"}></label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group-prepend">
                                                            <text className="input-group-text" >Correct Reinforcement</text>
                                                        </div>
                                                        <input type="text" className="form-control" id="correct_reinforcement" value={this.state.correct_reinforcement} minLength={2} maxLength={50} onChange={this.handleChangeCorrectAns} onBlur={this.correctReinforcementValidation} />
                                                        <label className={"input-error error_correct_reinforcement"}></label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group-prepend">
                                                            <text className="input-group-text" >Incorrect Reinforcement</text>
                                                        </div>
                                                        <input type="text" className="form-control" id="incorrect_reinforcement" value={this.state.incorrect_reinforcement} minLength={2} maxLength={50} onChange={this.handleChangeIncorrectAns} onBlur={this.incorrectReinforcementValidation} />
                                                        <label className={"input-error error_incorrect_reinforcement"}></label>
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </div>
                                    </Accordion>
                                </div>
                                <hr />
                                <div className="form-group">
                                    <div className="input-group-prepend">
                                        <text className="input-group-text" >Prompt</text>
                                    </div>
                                    <input type="text" className="form-control" id="prompt" minLength={2} maxLength={50} value={this.state.prompt} onChange={this.handleChangePrompt} onBlur={this.promptValidation} />
                                    <label className={"input-error error_prompt"}></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend">
                                        <text className="input-group-text" >Done text</text>
                                    </div>
                                    <input type="text" className="form-control" id="done_text" minLength={2} maxLength={30} value={this.state.done_text} onChange={this.handleChangeDoneText} onBlur={this.doneTextValidation} />
                                    <label className={"input-error error_done_text"}></label>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                            <div id="snackbar"></div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        )
    }
}