import React from 'react';
import Router from './Router';
import { Icon } from 'react-icons-kit'
import { smallDown } from 'react-icons-kit/entypo/smallDown'
import { smallRight } from 'react-icons-kit/entypo/smallRight'
import { trashO, plus } from 'react-icons-kit/fa'
import { eye, withLine } from 'react-icons-kit/entypo'
import { Button, Modal } from 'react-bootstrap'
import Footer from './Components/Footer';
import API from './Components/Global_API'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    borderRadius: 3,
    display: 'flex',
    flexWrap: 'wrap'
});

const styles = {
    title: {
        textAlign: 'center'
    },
    textinput: {
        resize: "none",
        marginBottom: 5,
        fontSize: 20,
        overflow: 'auto'
    },
    navigation: {
        color: '#8f9294',
        fontSize: 18,
        cursor: 'pointer',
        fontWeight: '600'
    },
    confirmButton: {
        backgroundColor: 'rgb(70, 180, 233)',
        borderColor: 'rgb(70, 180, 233)'
    },
    header: {
        maxWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}

export default class UnitScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            course_name: '',
            unit_name: '',
            description: '',
            img: '',
            course_id: '',
            unitid: '',
            Section: [],
            course: '',
            unit: '',
            noSectionsFound: false,
            shownicon: false,
            published: false,
            status: 'Unpublished',
            show: false,
            Item_for_delete: '',
            disable_save_button: true,
            saveButtonFlag: false,
            changeSectionStatus: '',
            PublishedSectionPopup: false,
            cropImagePopup: false,
            crop_image: null,
            is_assessment: '',
            crop: {
                unit: '%',
                width: 30,
                aspect: 2 / 1,
            },
            course_image: '',
            error_msg: '',
            errorPopup: false,
            image_error_popup: false
        };


        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        } else {
            this.handleClose = this.handleClose.bind(this);

            if (this.props.location.state) {
                this.state.course_name = this.props.location.state.course_name;
                this.state.course_id = this.props.location.state.course_id;
                this.state.course = this.props.location.state.course;
                this.state.unit = this.props.location.state.unit;

            }
            if (this.props.location.state.unit) {
                let cid = this.props.location.state.unit.unit_id;
                let cname = this.props.location.state.unit.unit_name;
                let cdesc = this.props.location.state.unit.unit_description;
                let cimg = this.props.location.state.unit.unit_image;
                this.state.status = this.props.location.state.unit.status;
                this.state.course = this.props.location.state.course;
                this.state.is_assessment = this.props.location.state.unit.is_assessment;
                this.state.unitid = cid;
                this.state.unit_name = cname;
                this.state.description = cdesc;
                this.state.img = cimg;
            }
            // if (this.props.location.state) {
            //     this.state.course_id = this.props.location.state.course_id
            // }
            if (this.state.unit_name == "") {
                this.state.disable_save_button = true
            } else {
                this.state.disable_save_button = false
            }
        }
    }

    handleShow(item) {
        this.setState({
            show: true,
            Item_for_delete: item
        });
    }

    handleClose() {
        this.setState({ show: false });
    }

    SectionDetails() {
        if (this.state.unitid) {
            let unit_id = JSON.stringify(
                {
                    unit_id: this.state.unitid
                });

            fetch(API + 'get_unit_details.php',
                {
                    method: 'POST',
                    body: unit_id
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    if (responseJsonFromServer.result == "success") {
                        this.setState({
                            status: responseJsonFromServer.unit_status,
                            unit_name: responseJsonFromServer.unit_name,
                            description: responseJsonFromServer.unit_description
                        })
                        if (responseJsonFromServer.section_data) {
                            this.setState({ Section: responseJsonFromServer.section_data })
                        } else {
                            this.setState({ Section: [] })
                            this.setState({ noSectionsFound: true })
                        }
                    } else {
                        alert("An unexpected error has benn occured");
                    }
                }).catch((error) => {
                    console.warn(error.message);
                });
        }
    }

    componentDidMount() {
        this.SectionDetails()
    }

    handleAssessment() {
        this.props.history.push("/assessment", { unit_id: this.state.unitid, course_name: this.state.course_name, unit_name: this.state.unit_name, course: this.state.course, unit: this.state.unit, course_id: this.state.course_id })
    }


    handleSubmit() {

        let errorCount = 0;
        if (this.state.unit_name.trim().length < 2 || this.state.unit_name.trim().length > 100) {
            if (this.state.unit_name.trim().length === 0) {
                document.getElementsByClassName('error_unit_title')[0].innerHTML = 'New unit number is required.';
            }
            document.getElementsByClassName('error_unit_title')[0].style.display = 'block';
            document.getElementById('unit_title').classList.add('error')
            errorCount++;
        }
        if (this.state.description.trim().length < 2 || this.state.description.trim().length > 150) {
            document.getElementsByClassName('error_unit_description')[0].innerHTML = 'New unit title is required.';
            document.getElementsByClassName('error_unit_description')[0].style.display = 'block';
            document.getElementById('unit_description').classList.add('error')
            errorCount++;
        } if (this.state.is_assessment.length < 1) {
            document.getElementsByClassName('error_assessmentStatus')[0].innerHTML = 'Select assessment status.';
            document.getElementsByClassName('error_assessmentStatus')[0].style.display = 'block';
            document.getElementById('assessmentStatus').classList.add('error')
            errorCount++;
        }

        if (errorCount == 0) {
            let unit_error = false
            if (this.state.unitid != '') {
                let status = JSON.stringify(
                    {
                        unit_id: this.state.unitid,
                        type: "unit",
                        text: this.state.status
                    });

                fetch(API + 'published_data.php',
                    {
                        method: 'POST',

                        body: status

                    }).then((response) => response.json()).then((responseJsonFromServer) => {

                        if (responseJsonFromServer.result == "success") {
                            this.SectionDetails()
                            // var x = document.getElementById("snackbar");
                            // x.className = "show";
                            // setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                            // x.innerHTML = responseJsonFromServer.msg;

                        } else {
                            this.setState({ errorPopup: true, error_msg: responseJsonFromServer.msg })
                            this.SectionDetails()
                            unit_error = true;

                        }

                    }).catch((error) => {
                        console.warn(error.message);
                    });
            }

            let flag = 'create';
            let unitid = '';
            let course_id = this.state.course_id;
            if (this.state.unitid != '') {
                flag = 'edit';
                course_id = course_id;
                unitid = this.state.unitid;
            }

            let unit = new FormData()
            unit.append('unit_name', this.state.unit_name);
            unit.append('unit_description', this.state.description);
            unit.append('course_id', course_id);
            unit.append('unit_images', this.state.course_image);
            unit.append('flag', flag);
            unit.append('is_assessment', this.state.is_assessment);
            unit.append('unitid', unitid);


            fetch(API + 'create_unit.php',
                {
                    method: 'POST',
                    body: unit
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    if (responseJsonFromServer.result === 'success') {

                        if (this.state.errorPopup == false && unit_error == false) {
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                            x.innerHTML = responseJsonFromServer.msg;
                        }
                        this.setState({ unitid: responseJsonFromServer.unit_id, unit: responseJsonFromServer, saveButtonFlag: false });
                        this.SectionDetails();
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                        this.SectionDetails();
                    }
                }).catch((error) => {
                    console.warn(error.message);
                });
        }
    }

    handleDeleteSection() {
        var item = this.state.Item_for_delete;

        let section = JSON.stringify(
            {
                section_id: item.section_id
            });

        fetch(API + 'delete_section.php',
            {
                method: 'POST',
                body: section
            }).then((response) => response.json()).then((responseJsonFromServer) => {

                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.setState({ show: false });
                    this.SectionDetails();
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    handleSection(section) {
        this.props.history.push('/SectionScreen', { section: section, unit_id: this.props.location.state.unit.unit_id, course_name: this.state.course_name, unit_name: this.state.unit_name, course: this.state.course, unit: this.state.unit, course_id: this.state.course_id })
    }

    handleChangeUnit = (e) => {
        this.setState({ unit_name: e.target.value, saveButtonFlag: true });
        if (e.target.value.trim() == "") {
            document.getElementsByClassName('error_unit_title')[0].innerHTML = 'New unit number is required.';
            document.getElementsByClassName('error_unit_title')[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ disable_save_button: true })
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 100) {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_unit_title')[0].innerHTML = 'New unit number must be between 2 to 100 character.';
            document.getElementsByClassName('error_unit_title')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            document.getElementsByClassName('error_unit_title')[0].innerHTML = "";
            document.getElementsByClassName('error_unit_title')[0].style.display = 'none';
            e.target.classList.remove('error');
            this.setState({ disable_save_button: false })
        }
    }

    handleChangeDescription = (e) => {
        if (e.target.value.trim() == "") {
            document.getElementsByClassName('error_unit_description')[0].innerHTML = 'New unit title is required.';
            document.getElementsByClassName('error_unit_description')[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ disable_save_button: true })
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 150) {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_unit_description')[0].innerHTML = 'New unit title must be between 2 to 150 character.';
            document.getElementsByClassName('error_unit_description')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            this.setState({ disable_save_button: false })
            document.getElementsByClassName('error_unit_description')[0].innerHTML = "";
            document.getElementsByClassName('error_unit_description')[0].style.display = 'none';
            e.target.classList.remove('error');
        }
        this.setState({ description: e.target.value, saveButtonFlag: true });
    }

    // handleChangeImage(e) {

    //     var fileName = e.target.files[0].name;
    //     var idxDot = fileName.lastIndexOf(".") + 1;
    //     var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    //     if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {

    //         let files = e.target.files;
    //         let reader = new FileReader();
    //         reader.readAsDataURL(files[0])
    //         reader.onload = (e) => {
    //             this.setState({ img: e.target.result, saveButtonFlag: true })
    //         }
    //     } else {
    //         this.setState({ image_error_popup: true })
    //     }

    // }

    addDefaultSrc(e) {
        e.target.src = require('../assets/click_image.png');
        e.target.onerror = null;
    }

    handleCourse() {
        this.props.history.push('/Create_Course', { course: this.state.course, course_id: this.state.course_id, course_name: this.state.course_name })
    }

    addDefaultSrcSection(e) {
        e.target.src = require('../assets/pta-logo2.png');
        e.target.onerror = null;
    }

    handleCreateSection() {
        this.props.history.push('/SectionScreen', { unit_id: this.state.unitid, course_name: this.state.course_name, unit_name: this.state.unit_name, course: this.state.course, unit: this.state.unit, course_id: this.state.course_id })
    }

    unitStatus = (e) => {
        this.setState({ status: e.target.value, saveButtonFlag: true })
    }

    unitAssessmentStatus = (e) => {
        this.setState({ is_assessment: e.target.value, saveButtonFlag: true })

        if (e.target.value.trim().length < 1) {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_assessmentStatus')[0].innerHTML = 'New unit title must be between 2 to 150 character.';
            document.getElementsByClassName('error_assessmentStatus')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            this.setState({ disable_save_button: false })
            document.getElementsByClassName('error_assessmentStatus')[0].innerHTML = "";
            document.getElementsByClassName('error_assessmentStatus')[0].style.display = 'none';
            e.target.classList.remove('error');
        }

    }

    handleSectionStatusPopupOpen(item) {
        this.setState({
            PublishedSectionPopup: true,
            changeSectionStatus: item
        });
    }

    handlePublishedSectionPopup = () => {
        this.setState({ PublishedSectionPopup: false });
    }

    handleSectionStatus(item) {
        var item = this.state.changeSectionStatus;
        if (item.status == 'Published') {
            item.status = "Unpublished"
        } else {
            item.status = "Published"
        }


        let data = JSON.stringify(
            {
                section_id: item.section_id,
                type: "section",
                text: item.status
            });
            console.log("data",data);
        fetch(API + 'published_data.php',
            {
                method: 'POST',

                body: data

            }).then((response) => response.json()).then((responseJsonFromServer) => {

                if (responseJsonFromServer.result == "success") {
                    this.state.Section.map((s) => {
                        if (s.section_id == item.section_id) {
                            s.status = item.status;
                        }
                    })
                    this.setState({ shownicon: true, PublishedSectionPopup: false })
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    x.innerHTML = responseJsonFromServer.msg;
                    this.SectionDetails();
                } else {
                    this.setState({ PublishedSectionPopup: false, errorPopup: true, error_msg: responseJsonFromServer.msg })
                    // x.innerHTML = responseJsonFromServer.msg;
                    this.SectionDetails();
                }

            }).catch((error) => {
                console.warn(error.message);
            });
    }


    handleChangeImage(e) {
        if (e.target.files[0]) {
            var fileName = e.target.files[0].name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                if (e.target.files && e.target.files.length > 0) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () =>
                        this.setState({ crop_image: reader.result, cropImagePopup: true, image_path: true, saveButtonFlag: true })
                    );
                    reader.readAsDataURL(e.target.files[0]);
                }
            } else {
                this.setState({ image_error_popup: true })
            }
        }
    }

    handleImageLoaded = (image) => {
        this.imageRef = image;
    }

    handleOnCropChange = (crop) => {
        this.setState({ crop });
    }

    handleOnCropCompleted = (crop, pixelCrop) => {
        this.makeClientCrop(crop);
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImageUrl = new File([u8arr], filename, { type: mime });
        this.setState({ croppedImageUrl })

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })

        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            this.setState({ set_image_path: this.fileUrl })
        });
    }

    handleImageCrop = (e) => {
        this.setState({ course_image: this.state.croppedImageUrl, cropImagePopup: false, img: this.state.set_image_path })
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {

        if (!result.destination) {
            return;
        }

        const Section = this.reorder(
            this.state.Section,
            result.source.index,
            result.destination.index
        );

        this.setState({
            Section
        });

        let section_sequence = []

        Section.map((item, index) =>
            section_sequence.push({ section_id: item.section_id, sequence_id: index })
        )

        let section_data = JSON.stringify({
            unit_id: this.state.unitid,
            sequence_data: section_sequence
        })


        fetch(API + 'drag_sections.php',
            {
                method: 'POST',

                body: section_data
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                console.warn(error.message);
            });
    };

    componentWillMount() {
        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    renderSection() {
        if (this.state.Section.length) {
            return (
                <div className="row" style={{ display: 'flex' }}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(droppableProvided, droppableSnapshot) => (
                                <div
                                    className="col-12"
                                    ref={droppableProvided.innerRef}
                                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                                >
                                    {this.state.Section.map((item, index) => (
                                        <div className="col-lg-4 col-md-6 col-xs-12" key={item.section_id}>
                                            <Draggable key={item.section_id} draggableId={item.section_id} index={index}>
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <div
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getItemStyle(
                                                            draggableSnapshot.isDragging,
                                                            draggableProvided.draggableProps.style
                                                        )}
                                                    >
                                                        <div className="course-card-item" key={item.section_id}>
                                                            <div className="react-card">
                                                                <a onClick={() => this.handleSection(item)} style={{ color: '#737373', textDecoration: 'none' }}>
                                                                    <div className="card-content">
                                                                        <div className="card-thumb">
                                                                            <img src={item.section_image} onError={this.addDefaultSrcSection} className="course-image" />
                                                                        </div>
                                                                        <div className="card-text">
                                                                            <h5 style={{ fontWeight: '600', fontSize: 17, color: '#000000', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} className="card-text--title">{item.section_name}</h5>
                                                                            <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', overflowWrap: 'anywhere' }}>{item.section_description}</p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-actions">
                                                                    <div className="CourseCardActionsContainer-sc-xm0i4x hHdVrU">
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div className="btn icon-btn" onClick={() => this.handleShow(item)} >
                                                                                <Icon size={20} icon={trashO} />
                                                                            </div>

                                                                            <div className="btn" onClick={() => this.handleSectionStatusPopupOpen(item)}>
                                                                                <label class="switch">
                                                                                    <input type="checkbox" checked={item.status == "Published" ? true : false} onClick={() => this.handleSectionStatusPopupOpen(item)} />
                                                                                    <span class="slider round"></span>
                                                                                </label>
                                                                                &nbsp;<span style={{ fontWeight: '600' }}>{item.status}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="btn" style={{ fontWeight: '600', paddingLeft: 0, }} onClick={() => this.handleSection(item)}>{item.lesson_count} Lesson</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Modal size="sm" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Body>Are you sure you want to delete this section?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>No</Button>
                            <Button style={styles.confirmButton} onClick={() => this.handleDeleteSection()}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="sm" show={this.state.PublishedSectionPopup} onHide={this.handlePublishedSectionPopup}>
                        <Modal.Body>Are you sure you want to {this.state.changeSectionStatus.status == 'Published' ? 'Unpublished' : 'Published'} this section?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handlePublishedSectionPopup}>No</Button>
                            <Button style={styles.confirmButton} onClick={() => this.handleSectionStatus()}>Yes</Button>
                        </Modal.Footer>
                    </Modal>


                </div>
            )
        } else if (this.state.noSectionsFound || !this.state.unitid) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', minHeight: 160, justifyContent: 'center', paddingTop: 10 }} >
                    <h3>No Sections found</h3>
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', minHeight: 160, justifyContent: 'center', paddingTop: 10 }}>
                    <img src={require('../assets/Loader.gif')} />
                </div>
            )
        }
    }


    render() {
        return (
            <div style={{ minHeight: '100vh' }}>
                <Router />
                <div style={{ backgroundColor: '#fff', paddingLeft: 30, paddingRight: 30, minHeight: '45vh' }}>
                    <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60 }}>
                        <div className="row" style={{ paddingTop: 10, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
                            <div className="col-md-12 col-sm-12 block-v-sm" style={{ display: 'flex' }}>
                                <div><a href="CoursesScreen" style={styles.navigation}>Courses</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleCourse()} style={styles.navigation}>{this.props.location.state.course.course_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a style={styles.navigation}>{this.state.unit_name ? this.state.unit_name : 'Untitled Unit'}</a></div><Icon size={20} icon={smallDown} />
                            </div>
                        </div>
                    </div>
                    <hr style={{ marginTop: -1 }} />
                    <div class="form-container">
                        <div style={{ width: 250, height: 150, backgroundColor: '#8f9294' }} >
                            <input
                                style={{ display: 'none' }}
                                ref={fileInput => this.fileInput = fileInput}
                                className="upload"
                                width={250}
                                onError={this.addDefaultSrc}
                                height={150}
                                className="mr-3"
                                src={this.state.img}
                                alt="Generic placeholder"
                                onChange={(e) => this.handleChangeImage(e)}
                                type="file"
                                accept="image/*"
                            />
                            <img
                                className="upload"
                                onClick={() => this.fileInput.click()}
                                width={250}
                                height={150}
                                onError={this.addDefaultSrc}
                                className="mr-3"
                                src={this.state.img}
                                alt="Generic placeholder"
                            />

                            <Modal show={this.state.cropImagePopup} onHide={() => { this.setState({ cropImagePopup: false }) }}>
                                <ReactCrop
                                    style={{ margin: 30 }}
                                    src={this.state.crop_image}
                                    onError={this.addDefaultSrc}
                                    onClick={() => this.fileInput.click()}
                                    className="upload"
                                    crop={this.state.crop}
                                    onImageLoaded={this.handleImageLoaded}
                                    onComplete={this.handleOnCropCompleted}
                                    onChange={this.handleOnCropChange}
                                    alt="Generic placeholder"
                                />
                                <Button style={{ marginLeft: 30, marginRight: 30, marginBottom: 30, backgroundColor: '#46b4e9', borderColor: '#2fabe6', fontWeight: 'bold' }} onClick={this.handleImageCrop}>Crop</Button>
                            </Modal>
                        </div>
                        <div class="input-field col-sm-12 col-lg-5 col-md-5">
                            <input class="form-control" placeholder="New unit number*" id="unit_title" autoComplete="off" autoCorrect="off" maxLength={100} minLength={2} value={this.state.unit_name} onChange={this.handleChangeUnit} style={styles.textinput} required />
                            <label class="input-error error_unit_title text-left"></label>
                            <textarea class="form-control" placeholder="New unit title*" id="unit_description" autoComplete="off" autoCorrect="off" maxLength={150} minLength={2} value={this.state.description} onChange={this.handleChangeDescription} rows="2" style={styles.textinput} />
                            <label class="input-error error_unit_description text-left"></label>
                            <div class="heading-editor-publishable" style={{ display: this.state.unitid == '' ? 'none' : '' }}>
                                <div className="col-lg-6 col-md-6 p-0" >
                                    <strong style={{ fontSize: '88%' }} class="heading-editor-publishable--label">This unit is&nbsp;&nbsp;&nbsp;</strong>
                                    <select onChange={this.unitStatus} style={{ height: 30, fontSize: '88%', fontWeight: '600', borderRadius: 3 }}>
                                        <option value="Published" selected={this.state.status === 'Published'} style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Published</option>
                                        <option value="Unpublished" selected={this.state.status === 'Unpublished'} style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Unpublished</option>
                                    </select>
                                </div>
                                <div className="col-lg-6 col-md-6 p-0">
                                    <Button variant="primary" onClick={() => this.handleAssessment()} size="lg" style={{ fontSize: '88%', fontWeight: '600', backgroundColor: '#46b4e9', borderColor: '#2fabe6' }} block>Assessment</Button>
                                </div>
                            </div>
                            <div className="form-submit-button">
                                <Button onClick={() => this.handleSubmit()} disabled={this.state.disable_save_button || !this.state.saveButtonFlag} variant="primary" size="lg" style={{ fontSize: '88%', width: 200, fontWeight: '600', backgroundColor: '#46b4e9', borderColor: '#2fabe6' }}>
                                    Save
                                </Button>
                                <div id="snackbar"></div>
                            </div>
                        </div>
                        <div class="input-field col-sm-12 col-lg-5 col-md-5">
                            <div class="heading-editor-publishable" >
                                <div className="col-lg-6 col-md-6 p-0" >
                                    <strong style={{ fontSize: '88%' }} class="heading-editor-publishable--label">This unit has Assessment*&nbsp;&nbsp;&nbsp;</strong>
                                    <select onChange={this.unitAssessmentStatus} id="assessmentStatus" value={this.state.is_assessment} disabled={this.state.unitid == '' ? false : true} style={{ height: 30, width: 100, fontSize: '88%', fontWeight: '600', borderRadius: 3 }}>
                                        <option value='' hidden style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Select</option>
                                        <option value={true} selected={this.state.status === true} style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Learner</option>
                                        <option value={false} selected={this.state.status === false} style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Assessor</option>
                                    </select>
                                    <label class="input-error error_assessmentStatus text-left"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-v-md" style={{ backgroundColor: '#ebf1f7', minHeight: '35vh', paddingRight: 30, paddingLeft: 30 }}>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-md-7 col-sm-5 block-v-sm">
                            <h4 style={{ color: '#8f9294', fontSize: 20 }}>SECTION</h4>
                        </div>
                        <div className="col-md-5 col-sm-7 text-right block-v-sm" >
                            <button disabled={!this.state.unitid} onClick={() => this.handleCreateSection()} className="btn btn-light" style={{ backgroundColor: '#46b4e9', borderColor: '#2fabe6', color: '#fff' }}><Icon size={20} icon={plus} className="icon icon-md icon-chevron-down" style={{ marginLeft: 10 }} />&nbsp;&nbsp;<strong>Create a new section</strong></button>
                        </div>
                        <div className="col" style={{ paddingTop: 20 }}>

                            {this.renderSection()}
                        </div>
                    </div>
                </div>
                <Modal size="sm" show={this.state.errorPopup} onHide={() => this.setState({ errorPopup: false })}>
                    <Modal.Body>{this.state.error_msg}</Modal.Body>
                    <Modal.Footer>
                        <Button style={styles.confirmButton} block onClick={() => this.setState({ errorPopup: false })}>Ok</Button>
                    </Modal.Footer>
                </Modal>
                <Modal size="sm" show={this.state.image_error_popup} onHide={() => this.setState({ image_error_popup: false })}>
                    <Modal.Body>Only images are accepted.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.setState({ image_error_popup: false })} block>Ok</Button>
                    </Modal.Footer>
                </Modal>
                <Footer />
            </div>
        )
    }
}