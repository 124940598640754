import React from 'react'
import { Accordion, Card, Button, Modal } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    borderRadius: 3,

    // change background colour if dragging
    background: isDragging ? "rgb(212, 212, 212)" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: "#80808066",
    padding: grid,
    width: 250,
    borderRadius: 3,
});

export default class Reorder_detail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.data.title,
            data: this.props.data.reordering_options,
            correct_answer: this.props.data.reordering_options,
            answer_reinforcement:'',
            answer_popup: true,
            correctAns: this.props.data.correct_reinforcement,
            incorrectAns: this.props.data.incorrect_reinforcement,
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            done_button_state: true,
            done_button_name: this.props.data.prompt,
        }

        let reorder_data = this.props.data.reordering_options;

        let data = [];
        reorder_data.map(item=>{
            return data.push(item)
        })

        function shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }
        this.state.data = shuffle(data)
    }

    DisableButtonFunction = (e) => {
        this.setState({
            done_button_state: false,
            done_button_name: this.state.done_text,
        })

    }


    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {
        this.DisableButtonFunction();
        if (!result.destination) {
            return;
        }

        const data = this.reorder(
            this.state.data,
            result.source.index,
            result.destination.index
        );

        this.setState({
            data
        });
    };

    handleAnswerpopup = () => {
        this.setState({ answer_popup: false })

        let arra1 = this.state.data;
        let arra2 = this.state.correct_answer;

        if (JSON.stringify(arra1) === JSON.stringify(arra2)) {
            this.setState({ answer_reinforcement: this.state.correctAns })
        } else {
            this.setState({ answer_reinforcement: this.state.incorrectAns })
        }
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }



    render() {
        return (
            <div>
                <div style={{ height: 340, display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowY: 'auto' }}>
                    <h5 style={{ color: '#fff', paddingTop: 15, overflowWrap: 'anywhere' }}> {this.state.title}</h5>
                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', paddingTop: 30 }}>

                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(droppableProvided, droppableSnapshot) => (
                                    <div
                                        ref={droppableProvided.innerRef}
                                        style={getListStyle(droppableSnapshot.isDraggingOver)}
                                    >
                                        {this.state.data.map((item, index) => (
                                            <Draggable key={item.reorder_id} draggableId={item.reorder_id} index={index}>
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <div
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getItemStyle(
                                                            draggableSnapshot.isDragging,
                                                            draggableProvided.draggableProps.style
                                                        )}
                                                    >
                                                        {/* <Icon icon={navicon} size={20} className="text-left" /> */}
                                                        {item.options}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleAnswerpopup() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                            {this.state.done_button_name}
                        </button>
                    </div>
                    <div style={{ backgroundColor: '#fff', visibility: this.state.answer_popup ? "hidden" : "visible", marginTop: -200, position: 'relative', marginLeft: -15, marginRight: -16, padding: 15, borderTop: 'solid', borderTopColor: 'rgba(0,0,0,0.4)', borderTopWidth: 5 }}>
                        <div style={{height: 85, overflowY: 'auto'}}>
                            <p><strong>{this.state.answer_reinforcement}</strong></p>
                            <div>
                                <p>{this.props.data.answer_text}</p>
                            </div>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-6">
                                <Button style={styles.continue_button} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} block>Continue</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    upload_button_label: {
        backgroundColor: '#fff',
        marginBottom: -150,
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
}