import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Media } from 'react-bootstrap'
import API from '../Components/Global_API'
import { Icon } from 'react-icons-kit'
import { trashO, plus } from 'react-icons-kit/fa'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    upload_button_label: {
        backgroundColor: '#fff',
        marginBottom: 5,
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}


export default class Peer_authoring extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.data.title,
            lesson_id: this.props.data.lesson_id,
            no_uploaded_message: this.props.data.no_media_updated_message,
            done_text: this.props.data.done_text,
            upload_button_label: this.props.data.upload_button_label,
            prompt: this.props.data.prompt,
            prev_review: true,
            nextButton: false,
            prevButton: false,
            current_index: this.props.current_index,
            course_image: this.props.course_image,
            // nextbutton: '',
            // prevbutton: true,
            question_id: this.props.data.question_id,
        }
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }


    handleSubmit = () => {

        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if(this.state.no_uploaded_message.trim().length < 2 || this.state.no_uploaded_message.trim().length > 250){
            document.getElementById("no_uploaded_message").focus();
        } else if(this.state.upload_button_label.trim().length < 2 || this.state.upload_button_label.trim().length > 30){
            document.getElementById("upload_button_label").focus();
        } else if(this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50){
            document.getElementById("prompt").focus();
        }  else if(this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30){
            document.getElementById("done_text").focus();
        } else {
            let data = JSON.stringify({
                lesson_id: this.state.lesson_id,
                title: this.state.title,
                no_media_updated_message: this.state.no_uploaded_message,
                upload_button_label: this.state.upload_button_label,
                prompt: this.state.prompt,
                done_text: this.state.done_text,
                question_id: this.state.question_id
            })


            fetch(API + 'edit_peer_authoring.php',
                {
                    method: 'POST',
                    body: data
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);

                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    console.warn(error.message);
                });

        }
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    No_uploaded_messageValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_no_uploaded_message')[0].innerHTML = "No uploaded message is required.";
            document.getElementsByClassName('error_no_uploaded_message')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_no_uploaded_message')[0].innerHTML = 'No uploaded message must be between 2 to 250 character.';
            document.getElementsByClassName('error_no_uploaded_message')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_no_uploaded_message')[0].innerHTML = "";
            document.getElementsByClassName('error_no_uploaded_message')[0].style.display = 'none';
        }
    }

    handleUpload_button_labelValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_upload_button_label')[0].innerHTML = "Upload button label is required.";
            document.getElementsByClassName('error_upload_button_label')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_upload_button_label')[0].innerHTML = 'Upload button label must be between 2 to 30 character.';
            document.getElementsByClassName('error_upload_button_label')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_upload_button_label')[0].innerHTML = "";
            document.getElementsByClassName('error_upload_button_label')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    handleDoneTextValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleChangeNo_uploaded_message = (e) => {
        this.setState({ no_uploaded_message: e.target.value });
    }

    handleChangeUpload_button_label = (e) => {
        this.setState({ upload_button_label: e.target.value });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDone_text = (e) => {
        this.setState({ done_text: e.target.value });
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    render() {
        return (
            <div className="row text-center">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="card card-sm">
                        <div className="card-content block-xl"  >
                            <div className="body-container" style={styles.body}>
                                <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                        <div className="row text-center">
                                            <div className="col-2">
                                                <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                            </div>
                                            <div className="col-6" style={{ paddingTop: 15 }}>
                                                {/* <text style={{color: '#fff',fontSize:20}}>title</text> */}
                                                <hr color='#fff' />
                                            </div>
                                            <div className="col-4">
                                                <div style={styles.dot_btn}>
                                                    <label style={{ color: '#fff', fontSize: 15, padding: 5 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                    <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ height: 340, paddingTop: 35, display: 'flex', justifyContent: 'center', flexDirection: 'column', overflowY: 'auto' }}>
                                            <h5 className="text-center" style={{ color: '#fff', paddingBottom: 10 }}>{this.state.title}</h5>
                                            <p style={{ color: '#fff' }}>{this.state.no_uploaded_message}</p>
                                            {/* <div className="text-center">
                                                <button className="btn" style={styles.previewbutton}>
                                                    {this.state.upload_button_label}
                                                </button>
                                            </div> */}
                                        </div>

                                        <div>
                                            <div className="text-center">
                                                <button className="btn" style={styles.upload_button_label}>
                                                    {this.state.upload_button_label}
                                                </button>
                                            </div>
                                            <hr color='#fff' />
                                            <div className="text-center">
                                                <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={styles.done_btn}>
                                                    {this.state.done_text}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                        <div>
                                            <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                <div className="text-center">
                                                    <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                        Review last slide
                                                    </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                        Restart lesson
                                                    </button>
                                                </div>
                                                {/* <div className="text-center">
                                                    <button className="btn" style={styles.previewbutton}>
                                                        Exit lesson
                                                    </button>
                                                </div> */}
                                                <div className="text-center">
                                                    <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                            <div className="col-4">
                                <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                            </div>
                            <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                <span >Slide {this.props.current_index + 1}</span>
                            </div>
                            <div className="col-4">
                                <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                    <div className="card-sm" >
                        <div className="card-content block-xl" style={{ height: 550, overflowY: 'scroll', overflowX: 'scroll' }}>
                            <h5 className="text-left" style={{ paddingBottom: 10 }}>Peer Authoring</h5>
                            <div className="text-left">
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <text className="input-group-text" style={styles.text}>Title*</text>
                                    </div>
                                    <input type="text" id="title" className="form-control" id="title" minLength={2} maxLength={250} value={this.state.title} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                    <label className="input-error error_title"></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <text className="input-group-text" style={styles.text}>No media uploaded message*</text>
                                    </div>
                                    <input type="text" className="form-control" id="no_uploaded_message" minLength={2} maxLength={250} value={this.state.no_uploaded_message} onChange={this.handleChangeNo_uploaded_message} onBlur={this.No_uploaded_messageValidation} />
                                    <label className="input-error error_no_uploaded_message"></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <text className="input-group-text" style={styles.text} >Upload button label*</text>
                                    </div>
                                    <input type="text" className="form-control" id="upload_button_label" value={this.state.upload_button_label} minLength={2} maxLength={30} onChange={this.handleChangeUpload_button_label} onBlur={this.handleUpload_button_labelValidation} />
                                    <label className={"input-error error_upload_button_label"}></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <text className="input-group-text" style={styles.text} >Prompt*</text>
                                    </div>
                                    <input type="text" className="form-control" id="prompt" value={this.state.prompt} minLength={2} maxLength={50} onChange={this.handleChangePrompt} onBlur={this.promptValidation} />
                                    <label className={"input-error error_prompt"}></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <text className="input-group-text" style={styles.text} >Done text*</text>
                                    </div>
                                    <input type="text" className="form-control" id="done_text" value={this.state.done_text} minLength={2} maxLength={30} onChange={this.handleChangeDone_text} onBlur={this.handleDoneTextValidation} />
                                    <label className={"input-error error_done_text"}></label>
                                </div>
                            </div>
                        </div>
                        <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                            <div id="snackbar"></div>
                        </div>
                    </div>
                </div>
            </div>
            // </div>
        )
    }
}