import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap'
import API from '../Components/Global_API'
import { Icon } from 'react-icons-kit'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}


export default class VideoFunctionality extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lesson_id: this.props.data.lesson_id,
            title: this.props.data.title,
            video_id: this.props.data.question_id,
            prompt: this.props.data.prompt,
            videoURL: API,
            video: this.props.data.video,
            videoForUpload: '',
            course_image: this.props.course_image,
            prev_review: true,
            percentage: 0,
            image_error_popup: false,
            video_name: '',
        }
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }


    handleSubmit = () => {

        if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else {
            const data = new FormData()
            data.append('video', this.state.videoForUpload);
            data.append('lesson_id', this.state.lesson_id);
            data.append('video_id', this.state.video_id);
            data.append('title', this.state.title);
            data.append('prompt', this.state.prompt);

            fetch(API + 'edit_video.php',
                {
                    method: 'POST',

                    body: data
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);

                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    console.warn(error.message);
                });
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;

        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;

        if (value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 0 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }


    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handleChangeVideo(e) {
        if (e.target.files[0]) {
            var fileName = e.target.files[0].name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "mp4" || extFile == "mkv" || extFile == "3gp") {
                var fileUrl = window.URL.createObjectURL(e.target.files[0]);
                this.setState({ video: fileUrl, videoForUpload: e.target.files[0], video_name: fileName })

                var formdata = new FormData();
                formdata.append('file1', document.getElementById('file1').files[0]);

                var request = new XMLHttpRequest();
                var x = document.getElementById("Video_uploading_percent")
                
                request.upload.addEventListener('progress', function (e) {
                    var file1Size = document.getElementById('file1').files[0].size;
                    if (e.loaded <= file1Size) {
                        var percent = Math.round(e.loaded / file1Size * 100);
                        var loader = document.getElementById('progress-bar-file1')
                        loader.style.width = `${percent}%`;
                        x.innerHTML = percent+'%'
                    }

                    if (e.loaded == e.total) {
                        var loader = document.getElementById('progress-bar-file1')
                        loader.style.width = `${100}%`;
                        x.innerHTML = 100+'%'
                    }
                });
                request.open('post', '/echo/html/');
                request.timeout = 45000;
                request.send(formdata);
            } else {
                this.setState({ image_error_popup: true })
            }
        }
    }



    render() {
        return (
            <div className="row text-center">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="card card-sm">
                        <div className="card-content block-xl"  >
                            <div className="body-container" style={styles.body}>
                                <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                        <div className="row text-center">
                                            <div style={{ width: '10%', paddingLeft: 5 }}>
                                                <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                            </div>
                                            <div style={{ paddingTop: 15, width: '60%' }}>
                                                {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                                <hr color='#fff' />
                                            </div>
                                            <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                <div style={styles.dot_btn}>
                                                    <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                    <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ height: 380, paddingTop: 35, overflowY: 'auto' }}>
                                            <h5 className="text-center" style={{ color: '#fff', paddingBottom: 10 }}>{this.state.title}</h5>

                                            <video id="background-video" muted autoPlay controls style={{ width: '100%', height: '50%' }}>
                                                <source src={API + this.state.video} type="video/mp4" />
                                            </video>

                                        </div>

                                        <div>
                                            <hr color='#fff' />
                                            <div className="text-center">
                                                <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={styles.done_btn}>
                                                    {this.state.prompt}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                        <div>
                                            <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                <div className="text-center">
                                                    <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                        Review last slide
                                                    </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                        Restart lesson
                                                    </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                            <div className="col-4">
                                <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                            </div>
                            <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                <span >Slide {this.props.current_index + 1}</span>
                            </div>
                            <div className="col-4">
                                <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                    <div className="card-sm" >
                        <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                            <h5 className="text-left" style={{ paddingBottom: 10 }}>Video</h5>
                            <div className="text-left">
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text}>Title</span>
                                    </div>
                                    <input type="text" className="form-control" id="title" maxLength={250} value={this.state.title} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                    <label className={"input-error error_title"}></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text}>Video*</span>
                                        <span className="pl-2 pt-2" style={styles.text}>{this.state.video_name}</span>
                                    </div>
                                    <input
                                        id="file1"
                                        style={{ display: 'none' }}
                                        ref={fileInput => this.fileInput = fileInput}
                                        className="upload"
                                        className="mr-3"
                                        onChange={(e) => this.handleChangeVideo(e)}
                                        type="file"
                                        accept="video/*"
                                    />
                                    <Button variant="default" style={{ backgroundColor: '#E6EBF0' }} onClick={() => this.fileInput.click()} block>
                                        Click anywhere to add a new video
                                    </Button>
                                    <div className="row" style={{ padding: '15px 5px' }}>
                                        <div class="progress-wrapper col-11">
                                            <div id="progress-bar-file1" class="progress"></div>
                                        </div>
                                        <div className="col-1" id="Video_uploading_percent"></div>
                                    </div>

                                </div>

                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text} >Prompt*</span>
                                    </div>
                                    <input type="text" className="form-control" id="prompt" value={this.state.prompt} minLength={2} maxLength={50} onChange={this.handleChangePrompt} onBlur={this.promptValidation} />
                                    <label className={"input-error error_prompt"}></label>
                                </div>
                            </div>
                        </div>
                        <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                            <div id="snackbar"></div>
                            <Modal size="sm" show={this.state.image_error_popup} onHide={() => this.setState({ image_error_popup: false })}>
                                <Modal.Body>Only videos are accepted.</Modal.Body>
                                <Modal.Footer>
                                    {/* <Button variant="secondary" onClick={this.handleClose}>No</Button> */}
                                    <Button variant="primary" onClick={() => this.setState({ image_error_popup: false })} block>Ok</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}