import React from 'react';
import '../Css/Styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import API from './Components/Global_API'
import Router from './Router';
import { Icon } from 'react-icons-kit'
import { trashO, gear, lock } from 'react-icons-kit/fa'
import { smallRight } from 'react-icons-kit/entypo/smallRight'
import Footer from './Components/Footer';
import Component_Loader from './Component_Loader';
import Create_question from './Functionality/Create_question';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const styles = {
    navigation: {
        color: '#8f9294',
        fontSize: 18,
        cursor: 'pointer',
        fontWeight: '600'
    },
    header: {
        maxWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    borderRadius: 3,
});

export default class Edit_Content extends React.Component {
    constructor(props) {
        super(props)

        this.loadComponent = this.loadComponent.bind(this);
        this.state = {
            lesson_id: '',
            allquestions: [],
            current_index: 0,
            current_question_type: '',
            show: false,
            course: '',
            course_id: '',
            section_name: '',
            unit: '',
            unit_name: '',
            section: '',
            lesson: '',
            lesson_name: '',
            course_name: '',
            alert: false,
            component_data: [],
            course_image: '',
            Item_for_delete: '',
            question_sequence: []
        }
        this.nextQuestion = this.nextQuestion.bind(this);
        this.handleClosePopup = this.handleClosePopup.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        let loginUser = localStorage.getItem("loginUser")

        if (loginUser == null) {
            window.location.href = '/';
        } else {
            if (this.props.location.state) {
                this.state.course = this.props.location.state.course;
                this.state.course_image = this.props.location.state.course.image;
                this.state.course_name = this.props.location.state.course_name;
                this.state.unit = this.props.location.state.unit;
                this.state.unit_name = this.props.location.state.unit_name;
                this.state.section = this.props.location.state.section;
                this.state.section_name = this.props.location.state.section_name;
                this.state.lesson = this.props.location.state.lesson;
                this.state.lesson_name = this.props.location.state.lesson_name;
                this.state.lesson_id = this.props.location.state.lesson_id;
                this.state.course_id = this.props.location.state.course_id
            }
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const allquestions = this.reorder(
            this.state.allquestions,
            result.source.index,
            result.destination.index
        );

        this.setState({
            allquestions
        });


        allquestions.map((item, index) =>
            this.state.question_sequence.push({ question_id: item.question_id, question_type: item.question_type, sequence_id: index })
        )

        let question_data = JSON.stringify({
            lesson_id: this.state.lesson_id,
            sequence_data: this.state.question_sequence
        })

        fetch(API + 'drag_lesson_questions.php',
            {
                method: 'POST',

                body: question_data
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                console.warn(error.message);
            });

    };

    nextQuestion = (current_index) => {

        if (this.state.allquestions[current_index]) {
            this.setState({ current_index: current_index })
            this.setState({ current_question_type: this.state.allquestions[current_index].question_type })
            this.setState({ component_data: this.state.allquestions[current_index] })
        }
    }

    handleUpdateQuetionData = () => {
        this.handleQuestionDetails();
        this.setState({
            current_index: this.state.current_index
        })
    }

    handleShowTemplate = () => {
        this.handleQuestionDetails();
        this.setState({
            show: false,
            current_index: this.state.allquestions.length - 1
        })
    }

    handleShowPopup(qItem, qIndex) {

        this.setState({
            alert: true,
            Item_for_delete: qItem,
            Item_index_for_delete: qIndex
        });
    }

    handleClosePopup() {
        this.setState({ alert: false });
    }

    handleClose() {
        this.setState({ show: false });
    }
    handleShow() {

        this.setState({ show: true });
    }

    handleQuestionDetails() {

        fetch(API + 'get_lesson_questions.php',
            {
                method: 'POST',

                body: JSON.stringify({ 'lesson_id': this.state.lesson_id })
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                if (responseJsonFromServer.result == 'success') {
                    const question_data = responseJsonFromServer.question_data.map((item, index) => {
                        item.id = String(index + 1)
                        return item
                    })
                    this.setState({ 'allquestions': question_data });
                    this.setState({ 'current_question_type': question_data[0].question_type });
                    this.nextQuestion(this.state.current_index)
                }
            }).catch((error) => {
                console.warn(error.message);
            });
    }

    componentDidMount() {
        this.handleQuestionDetails();
    }

    loadComponent(pagename, index) {
        this.handleQuestionDetails();
        this.setState({ current_index: 0 })
        this.setState({ current_question_type: 'blank' })
        setTimeout(() => {
            this.setState({ current_index: index })
            this.setState({ current_question_type: pagename })
        }, 100);
    }

    handleCourse() {
        this.props.history.push("/Create_Course", { course: this.state.course, course_id: this.state.course_id });
    }

    handleUnit() {
        this.props.history.push("/UnitScreen", { unit: this.state.unit, course_name: this.state.course_name, course: this.state.course, course_id: this.state.course_id });
    }

    handleSection() {
        this.props.history.push("/SectionScreen", { unit: this.state.unit, course: this.state.course, section: this.state.section, course_name: this.state.course_name, unit_name: this.state.unit_name, course_id: this.state.course_id });
    }

    handleLesson() {
        this.props.history.push("/Create_lesson", { lesson: this.state.lesson, section_id: this.state.section.section_id, lesson_id: this.state.lesson_id, unit: this.state.unit, course_id: this.state.course_id, course: this.state.course, section: this.state.section, course_name: this.state.course_name, unit_name: this.state.unit_name, section_name: this.state.section_name });
    }

    handleDeleteQuestion() {
        var qItem = this.state.Item_for_delete;

        let question = JSON.stringify(
            {
                question_id: qItem.question_id,
                lesson_id: qItem.lesson_id,
                question_type: qItem.question_type
            });

        fetch(API + 'delete_question.php',
            {
                method: 'POST',

                body: question
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    this.setState({ alert: false, current_index: 0 });
                    this.handleQuestionDetails();
                    x.innerHTML = responseJsonFromServer.msg;
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    render() {
        return (
            <div style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <Router />
                <div style={{ backgroundColor: '#EEF2F6' }}>
                    <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60, minHeight: '10vh' }}>
                        <div className="row" style={{ paddingLeft: 20, paddingTop: 25, paddingBottom: 15 }}>
                            <div className="col-lg-12 col-md-12 col-sm-12 block-v-sm" style={{ display: 'flex' }}>
                                <div><a href="CoursesScreen" style={styles.navigation}>Courses</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleCourse()} style={styles.navigation}>{this.props.location.state.course.course_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleUnit()} style={styles.navigation}>{this.props.location.state.unit.unit_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleSection()} style={styles.navigation}>{this.props.location.state.section.section_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleLesson()} style={styles.navigation}>{this.props.location.state.lesson.lesson_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a style={styles.navigation}> Content </a></div>
                            </div>
                        </div>
                    </div>
                    <div className="body-container" style={{ paddingBottom: 20, minHeight: '67vh' }}>
                        <div className="container-fluid" >
                            <div className="row text-center">
                                <div className="col-lg-9 col-md-12 col-sm-12">
                                    <div className="card-sm" style={{ marginTop: 15 }}>
                                        <div className="card-content block-xl" style={{ minHeight: 600 }}>
                                            <Component_Loader handleUpdateQuetionData={this.handleUpdateQuetionData} course_image={this.state.course_image} que_count={this.state.allquestions.length} nextQuestion={this.nextQuestion} current_index={this.state.current_index} component_data={this.state.allquestions[this.state.current_index]} component_type={this.state.current_question_type} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-5 col-sm-12" style={{ marginTop: 15 }}>
                                    <div className="card card-sm">
                                        <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', backgroundColor: '#223042' }}>
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                <Droppable droppableId="droppable">
                                                    {(droppableProvided, droppableSnapshot) => (
                                                        <div
                                                            ref={droppableProvided.innerRef}
                                                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                        >
                                                            {this.state.allquestions.map((qItem, qIndex) => (
                                                                qItem.delete_flag == "true" ?
                                                                    <Draggable key={qItem.id} draggableId={qItem.id} index={qIndex}>
                                                                        {(draggableProvided, draggableSnapshot) => (
                                                                            <div
                                                                                ref={draggableProvided.innerRef}
                                                                                {...draggableProvided.draggableProps}
                                                                                {...draggableProvided.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    draggableSnapshot.isDragging,
                                                                                    draggableProvided.draggableProps.style
                                                                                )}
                                                                            >
                                                                                <ListGroup style={{ paddingBottom: 10 }}>
                                                                                    <ListGroup.Item style={{ backgroundColor: '#223042', marginBottom: -15, cursor: 'pointer' }}>
                                                                                        <div className="row" onClick={this.loadComponent.bind(this, qItem.question_type, qIndex)}>
                                                                                            <div className="col-10" >
                                                                                                <p className="text-left" style={{ fontWeight: '600', marginBottom: -1, paddingBottom: 5, fontSize: 16, color: '#6f7d8e' }}>{qIndex + 1 + '. ' + qItem.question_type.toUpperCase()}</p>
                                                                                                <p className="text-left" style={{ fontSize: 16, lineHeight: 1.3, fontWeight: '500', color: this.state.current_index == qIndex ? '#fff' : '#6f7d8e' }} >{qItem.title}</p>
                                                                                            </div>
                                                                                            <div className="col-1" >
                                                                                                {qItem.delete_flag == "true" ? <Icon size={20} icon={gear} onClick={this.handleShow} className="btn" style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} /> : <Icon size={20} icon={lock} onClick={this.handleShow} className="btn" style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} />}
                                                                                                {qItem.delete_flag == "true" ? <Icon size={20} className="btn" onClick={() => this.handleShowPopup(qItem, qIndex)} icon={trashO} style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} /> : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </ListGroup.Item>
                                                                                </ListGroup>
                                                                            </div>
                                                                        )}
                                                                    </Draggable> :
                                                                    <ListGroup style={{ paddingBottom: 10 }}>
                                                                        <ListGroup.Item style={{ backgroundColor: '#223042', marginBottom: -15, cursor: 'pointer' }}>
                                                                            <div className="row" onClick={this.loadComponent.bind(this, qItem.question_type, qIndex)}>
                                                                                <div className="col-10" >
                                                                                    <p className="text-left" style={{ fontWeight: '600', marginBottom: -1, paddingBottom: 5, fontSize: 16, color: '#6f7d8e' }}>{qIndex + 1 + '. ' + qItem.question_type.toUpperCase()}</p>
                                                                                    <p className="text-left" style={{ fontSize: 16, lineHeight: 1.3, fontWeight: '500', color: this.state.current_index == qIndex ? '#fff' : '#6f7d8e' }} >{qItem.title}</p>
                                                                                </div>
                                                                                <div className="col-1" >
                                                                                    {qItem.delete_flag == "true" ? <Icon size={20} icon={gear} onClick={this.handleShow} className="btn" style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} /> : <Icon size={20} icon={lock} onClick={this.handleShow} className="btn" style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} />}
                                                                                    {qItem.delete_flag == "true" ? <Icon size={20} className="btn" onClick={() => this.handleShowPopup(qItem, qIndex)} icon={trashO} style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} /> : null}
                                                                                </div>
                                                                            </div>
                                                                        </ListGroup.Item>
                                                                    </ListGroup>
                                                            ))}
                                                            {droppableProvided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            <Modal size="sm" show={this.state.alert} onHide={this.handleClosePopup}>
                                                <Modal.Body>Are you sure you want to delete this question?</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={this.handleClosePopup}>No</Button>
                                                    <Button variant="primary" onClick={() => this.handleDeleteQuestion()}>Yes</Button>
                                                </Modal.Footer>
                                            </Modal>
                                            <div id="snackbar"></div>
                                        </div>
                                    </div>
                                    <button className="btn btn-dark" style={{ width: '100%', marginTop: 10 }} onClick={this.handleShow}>+ Add a new slide</button>
                                    <Modal size="lg" style={{ height: '100%', borderRadius: 6 }} show={this.state.show} onHide={this.handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Choose a template</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ height: 700, overflowY: 'auto', backgroundColor: '#EEF2F6', margin: 5 }}>
                                            <Create_question handleShowTemplate={this.handleShowTemplate} lesson_id={this.state.lesson_id} />
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}