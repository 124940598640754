import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from 'react-icons-kit'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import { Button } from 'react-bootstrap'
import API from '../Components/Global_API'

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    },
    textinput: {
        resize: "none",
        marginBottom: 5,
        fontSize: 20,
        overflow: 'auto'
    },
}

export default class ScrollableFunctionality extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            question_id: this.props.data.question_id,
            lesson_id: this.props.data.lesson_id,
            title: this.props.data.title,
            content: this.props.data.content,
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            nextButton: false,
            prevButton: false,
            current_index: this.props.current_index,
            course_image: this.props.course_image,
            nextbutton: '',
            // prevbutton: true,
            done_button_state: true,
            done_button_name: this.props.data.prompt,
            prev_review: true
        };

    }

    DisableButtonFunction = (event) => {
        if (event) {
            this.setState({
                done_button_state: false,
                done_button_name: this.state.done_text
            })
        }
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleSubmit = (e) => {
        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.content.trim().length < 2 || this.state.content.trim().length > 3000) {
            document.getElementById("content").focus();
        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {
            let course_imag = JSON.stringify(
                {
                    lesson_id: this.state.lesson_id,
                    title: this.state.title,
                    content: this.state.content,
                    prompt: this.state.prompt,
                    done_text: this.state.done_text,
                    question_id: this.state.question_id
                });



            fetch(API + 'edit_scrollable_question.php',
                {
                    method: 'POST',

                    body: course_imag
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }
                }).catch((error) => {
                    console.warn(error.message);
                });
        }
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }


    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    contentValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_content')[0].innerHTML = "Content is required.";
            document.getElementsByClassName('error_content')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 3000) {
            document.getElementsByClassName('error_content')[0].innerHTML = "Content must be between 2 to 3000 character.";
            document.getElementsByClassName('error_content')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_content')[0].innerHTML = "";
            document.getElementsByClassName('error_content')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }




    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleChangeContent = (e) => {
        this.setState({ content: e.target.value });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value });
    }

    render() {
        return (
            <div className="row text-center">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="card card-sm">
                        <div className="card-content block-xl"  >
                            <div className="body-container" style={styles.body}>
                                <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }} >
                                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                        <div className="row text-center">
                                            <div style={{ width: '10%', paddingLeft: 5 }}>
                                                <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                            </div>
                                            <div style={{ paddingTop: 15, width: '60%' }}>
                                                {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                                <hr color='#fff' />
                                            </div>
                                            <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                <div style={styles.dot_btn}>
                                                    <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                    <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ height: 380, fontSize: 15, overflowY: 'auto', color: '#fff', paddingTop: 10 }} onScroll={this.DisableButtonFunction} >
                                            <h5>{this.state.title}</h5>
                                            <div style={{minHeight: 380}}>
                                                <p className="text-left" style={{ whiteSpace: 'pre-line', paddingTop: 20, fontSize:16 }} >
                                                    {this.state.content}
                                                </p>
                                            </div>

                                        </div>

                                        <div>
                                            <hr color='#fff' />
                                            <div className="text-center">
                                                <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                    {this.state.done_button_state ? this.state.prompt : this.state.done_text}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                        <div>
                                            <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                <div className="text-center">
                                                    <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                        Review last slide
                                                    </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                        Restart lesson
                                                    </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row col-12 text-center" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                            <div className="col-4">
                                <Button variant="default" disabled={this.state.prevButton} onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                            </div>
                            <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                <span >Slide {this.props.current_index + 1}</span>
                            </div>
                            <div className="col-4">
                                <Button disabled={this.state.nextButton} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                    <div className="card-sm" >
                        {/* <form> */}
                        <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                            <h5 className="text-left" style={{ paddingBottom: 10 }}>Scrollable</h5>
                            <div className="text-left">
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text} >Title*</span>
                                    </div>
                                    <input type="text" className="form-control" id="title" value={this.state.title} minLength={2} maxLength={250} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                    <label className="input-error error_title"></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text}>Content*</span>
                                    </div>
                                    <textarea data-default="" value={this.state.content} style={styles.textinput} id="content" minLength={2} maxLength={3000} onChange={this.handleChangeContent} rows="7" className="form-control" onBlur={this.contentValidation} ></textarea>
                                    <label className="input-error error_content"></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text}>Prompt*</span>
                                    </div>
                                    <input type="text" value={this.state.prompt} id="prompt" maxLength={50} minLength={2} onChange={this.handleChangePrompt} className="form-control" onBlur={this.promptValidation} />
                                    <label className="input-error error_prompt"></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text}>Done text*</span>
                                    </div>
                                    <input type="text" value={this.state.done_text} id="done_text" maxLength={30} minLength={2} onChange={this.handleChangeDoneText} className="form-control" onBlur={this.done_textValidation} />
                                    <label className="input-error error_done_text"></label>
                                </div>
                            </div>

                        </div>
                        <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</Button>
                            <div id="snackbar"></div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        )
    }
}