import React from 'react'
import { Icon } from 'react-icons-kit'
import { smallDown, smallRight, smallLeft } from 'react-icons-kit/entypo'
import { pencil } from 'react-icons-kit/fa/pencil'
import { Accordion, Card, Button, Media } from 'react-bootstrap'

export default class Expandable_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.data.expandable_data,
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            done_button_state: true,
            title: this.props.data.title
        }
    }

    DisableButtonFunction = param => e => {
        let data_result = this.state.user;
        data_result.map((val) => {
            if (val.expandable_details_id === param) {
                val.selected = true;

            }
        })
        const result = data_result.filter(data_result => data_result.selected !== true);
        if (result.length == 0) {
            this.setState({ done_button_state: false })
        }
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    render() {
        return (
            <div>
                <div style={{ height: 340, overflowY: 'auto' }}>
                    <h5 className="text-center" style={{ color: '#fff', paddingBottom: 20, paddingTop: 20, overflowWrap: 'anywhere' }}>{this.state.title}</h5>
                    <Accordion style={{ textAlign: 'left' }}>
                        {this.state.user.map((item, i) => (
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey={i} onClick={this.DisableButtonFunction(item.expandable_details_id)}>
                                    {item.title}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={i}>
                                    <Card.Body>{item.content}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                            {this.state.done_button_state ? this.state.prompt : this.state.done_text}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
}