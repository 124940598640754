import React from 'react';
import API from './Components/Global_API';

const styles = {
    screen: {
        paddingBottom: 20
    }
}
export default class Create_assessment_question extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            done_text: `Ok, I'm done`,
            lesson_id: this.props.lesson_id,
            unit: this.props.unit
        }
    }

    handleCreateFreeText = () => {
        let free_text = JSON.stringify({
            lesson_id: '0',
            unit_id: this.state.unit.unit_id,
            title: "What are some?",
            prompt: 'Type your response',
            done_text: this.state.done_text
        })


        fetch(API + 'create_free_text.php',
            {
                method: 'POST',

                body: free_text
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.props.handleShowTemplate();
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                console.warn(error.message);
            });
    }

    handleCreateMultipleChoice = () => {
        let multiple_choice = JSON.stringify({
            unit_id: this.state.unit.unit_id,
            title: "What are some of the problems with long stints of elearning?",
            options_data: [{ options: " All of the" }, { options: "This" }, { options: "That" }, { options: "The other" }],
            prompt: 'Tap on the correct answer',
            answer: 'That',
            done_text: this.state.done_text
        })

        fetch(API + 'create_assessement_multiplechoice_question.php',
            {
                method: 'POST',

                body: multiple_choice
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.props.handleShowTemplate();
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                console.warn(error.message);
            });
    }

    handleCreateExpandableList = () => {
        let multiple_choice = JSON.stringify({
            unit_id: this.state.unit.unit_id,
            title: "Expandable List",
            list: [{ title: "Client name", content: "Type answer here" }, { title: "Date of birth", content: "Type answer here" }, { title: "Contact number", content: "Type answer here" }, { title: "Next of kin", content: "Type answer here" }],
            prompt: 'Select an item to see more',
            done_text: this.state.done_text
        })

        fetch(API + 'create_assessment_expandable.php',
            {
                method: 'POST',

                body: multiple_choice
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.props.handleShowTemplate();
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                console.warn(error.message);
            });
    }

    handleCreateQuestionPool = () => {
        let multiple_choice = JSON.stringify({
            unit_id: this.state.unit.unit_id,
            question_data: [{ title: "A type of long bone", prompt: "Select the correct answer", done_text: "Ok, I'm done", options_data: [{ options: "Femur" }, { options: "Carpals" }, { options: "Scapula" }, { options: "Sturnum" }], answer: 'Carpals' },
            { title: "A type of sesamoid bone", prompt: "Select the correct answer", done_text: "Ok, I'm done", options_data: [{ options: "Patella" }, { options: "Tibia" }, { options: "Metacarpals" }, { options: "Radius" }], answer: 'Radius' },
            { title: "A type of flat bone", prompt: "Select the correct answer", done_text: "Ok, I'm done", options_data: [{ options: "Sternum" }, { options: "Tibia" }, { options: "Metacarpals" }, { options: "Radius" }], answer: 'Tibia' }],

        })

        fetch(API + 'create_question_pool.php',
            {
                method: 'POST',

                body: multiple_choice
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.props.handleShowTemplate();
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                console.warn(error.message);
            });
    }

    render() {
        return (
            <div className="row">
                {this.state.unit.is_assessment == "true" ?
                    <>
                        <div className="btn col-lg-4 col-md-4 col-xs-6" style={styles.screen} onClick={this.handleCreateFreeText}>
                            <img src={require('../assets/free_text.jpg')} style={{ width: '100%', height: '80%' }} />
                            <div className="col-lg-12 col-md-12 col-xs-12" style={{ backgroundColor: '#fff', padding: 10 }}>
                                <text>Free Text</text>
                            </div>
                        </div>
                        <div className="btn col-lg-4 col-md-4 col-xs-6" style={styles.screen} onClick={this.handleCreateMultipleChoice}>
                            <img src={require('../assets/multiple_choice.jpg')} style={{ width: '100%', height: '80%' }} />
                            <div className="col-lg-12 col-md-12 col-xs-12" style={{ backgroundColor: '#fff', padding: 10 }}>
                                <text>Multiple Choice</text>
                            </div>
                        </div>
                        <div className="btn col-lg-4 col-md-4 col-xs-6" style={styles.screen} onClick={this.handleCreateExpandableList}>
                            <img src={require('../assets/multiple_choice.jpg')} style={{ width: '100%', height: '80%' }} />
                            <div className="col-lg-12 col-md-12 col-xs-12" style={{ backgroundColor: '#fff', padding: 10 }}>
                                <text>Expandable list</text>
                            </div>
                        </div>
                        <div className="btn col-lg-4 col-md-4 col-xs-6" style={styles.screen} onClick={this.handleCreateQuestionPool}>
                            <img src={require('../assets/multiple_choice.jpg')} style={{ width: '100%', height: '80%' }} />
                            <div className="col-lg-12 col-md-12 col-xs-12" style={{ backgroundColor: '#fff', padding: 10 }}>
                                <text>Question pool</text>
                            </div>
                        </div>
                    </> :
                    <div className="btn col-lg-4 col-md-4 col-xs-6" style={styles.screen} onClick={this.handleCreateExpandableList}>
                        <img src={require('../assets/multiple_choice.jpg')} style={{ width: '100%', height: '80%' }} />
                        <div className="col-lg-12 col-md-12 col-xs-12" style={{ backgroundColor: '#fff', padding: 10 }}>
                            <text>Expandable list</text>
                        </div>
                    </div>
                }
                <div id="snackbar"></div>
            </div>
        )
    }
}