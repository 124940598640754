import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from 'react-icons-kit'
import { trashO, plus, navicon } from 'react-icons-kit/fa'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import { Accordion, Card, Button, Modal } from 'react-bootstrap'
import API from '../Components/Global_API'
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    radio: {
        borderWidth: 1,
        backgroundColor: '#fff',
        margin: 5,
        paddingLeft: 20,
        borderRadius: 5,
        fontSize: 15,
        paddingTop: 5
    },
    continue_button: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.2)',
        padding: 10,
        backgroundColor: '#fff',
        color: '#000000'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    borderRadius: 3,
    background: isDragging ? "rgb(212, 212, 212)" : "#fff",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: "#80808066",
    padding: grid,
    width: 250,
    borderRadius: 3,
});

export default class Reorder extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            title: this.props.data.title,
            answerText: this.props.data.answer_text,
            correctAns: this.props.data.correct_reinforcement,
            incorrectAns: this.props.data.incorrect_reinforcement,
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            items: this.props.data.reordering_options,
            reorder_data: this.props.data.reordering_options,
            lessonId: this.props.data.lesson_id,
            prev_review: true,
            content_validation: false,
            PublishedSectionPopup: false,
            nextButton: false,
            prevButton: false,
            current_index: this.props.current_index,
            course_image: this.props.course_image,
            question_id: this.props.data.question_id,
            done_button_name: this.props.data.prompt,
            done_button_state: true,
            answer_popup: true,
        }





        let reorder_data = this.props.data.reordering_options;

        let data = [];
        reorder_data.map(item => {
            return data.push(item)
        })

        function shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }
        this.state.reorder_data = shuffle(data)

        this.Add = this.Add.bind(this);
        this.Remove = this.Remove.bind(this);

    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {
            this.DisableButtonFunction();

            if (!result.destination) {
                return;
            }

            const reorder_data = this.reorder(
                this.state.reorder_data,
                result.source.index,
                result.destination.index
            );

            this.setState({
                reorder_data
            });
    };

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleAnswerpopup = () => {
        this.setState({ answer_popup: false })

        let arra1 = this.state.items;
        let arra2 = this.state.reorder_data;

        if (JSON.stringify(arra1) === JSON.stringify(arra2)) {
            this.setState({ answer_reinforcement: this.state.correctAns })
        } else {
            this.setState({ answer_reinforcement: this.state.incorrectAns })
        }
        // this.handleDoneButton(this.props.current_index + 1)

    }

    DisableButtonFunction = (e) => {
        this.setState({
            done_button_state: false,
            done_button_name: this.state.done_text,
        })

    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handleSubmit = (e) => {
        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.content_validation) {
            console.log("not valid");
        } else if (this.state.answerText.trim().length > 150) {
            document.getElementById("answer_text").focus();
        } else if (this.state.correctAns.trim().length < 2 || this.state.correctAns.trim().length > 50) {
            document.getElementById("correct_reinforcement").focus();
        } else if (this.state.incorrectAns.trim().length < 2 || this.state.incorrectAns.trim().length > 50) {
            document.getElementById("incorrect_reinforcement").focus();
        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {

            var reordering_data = []
            this.state.items.map(item =>
                reordering_data.push({ options: item.options })
            )

            let reorder_item = JSON.stringify(
                {
                    lesson_id: this.state.lessonId,
                    title: this.state.title,
                    prompt: this.state.prompt,
                    done_text: this.state.done_text,
                    answer_text: this.state.answerText,
                    correct_reinforcement: this.state.correctAns,
                    incorrect_reinforcement: this.state.incorrectAns,
                    reorder_id: this.state.question_id,
                    options_data: reordering_data,

                });

            fetch(API + 'edit_reordering.php',
                {
                    method: 'POST',

                    body: reorder_item
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    console.warn(error.message);
                });


        }
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    contentValidation = (i, e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_content' + i)[0].innerHTML = "Content is required.";
            document.getElementsByClassName('error_content' + i)[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ content_validation: true })
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 100) {
            document.getElementsByClassName('error_content' + i)[0].innerHTML = 'Content must be between 2 to 100 character.';
            document.getElementsByClassName('error_content' + i)[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ content_validation: true })
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            this.setState({ content_validation: false })
            document.getElementsByClassName('error_content' + i)[0].innerHTML = "";
            document.getElementsByClassName('error_content' + i)[0].style.display = 'none';
        }
    }

    answerTextValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length < 0 || value.trim().length > 150) {
            document.getElementsByClassName('error_answer_text')[0].innerHTML = 'Correct answer must be between 2 to 150 character.';
            document.getElementsByClassName('error_answer_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_answer_text')[0].innerHTML = "";
            document.getElementsByClassName('error_answer_text')[0].style.display = 'none';
        }
    }

    correct_reinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "Correct reinforcement is required.";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = 'Correct reinforcement must be between 2 to 50 character.';
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'none';
        }
    }

    incorrect_reinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "Incorrect reinforcement is required.";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = 'Incorrect reinforcement must be between 2 to 50 character.';
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value })
    }

    handleChangeContent = async (i, e) => {

        let value = e.target.value;
        let word = this.state.items;
        word.map((item, index) => {
            if (index == i) {
                item.options = value;

            }
        })
        await this.setState({ items: word });

    }

    handleChangeAnswerText = (e) => {
        this.setState({ answerText: e.target.value });
    }

    handleChangeCorrectAns = (e) => {
        this.setState({ correctAns: e.target.value });
    }

    handleChangeIncorrectAns = (e) => {
        this.setState({ incorrectAns: e.target.value });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value })
    }



    Add() {
        let add = this.state.items;
        if (this.state.items.length < 6) {
            let newu = { options: 'size' }

            add.push(newu)
            this.setState({
                items: add,
            })
        }
    }

    handleSectionStatusPopupOpen(item) {
        this.setState({
            PublishedSectionPopup: true,
            changeSectionStatus: item
        });
    }

    handlePublishedSectionPopup = () => {
        this.setState({ PublishedSectionPopup: false });
    }

    Remove = async () => {
        var i = this.state.changeSectionStatus;
        let words = this.state.items;
        if (this.state.items.length > 2) {
            words.splice(i, 1);
            await this.setState({ items: words, PublishedSectionPopup: false });
        }
    }

    render() {
        return (
            <div className="row text-center">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="card card-sm">
                        <div className="card-content block-xl"  >
                            <div className="body-container" style={styles.body}>
                                <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                        <div className="row text-center">
                                            <div className="col-2">
                                                <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                            </div>
                                            <div className="col-6" style={{ paddingTop: 15 }}>
                                                {/* <text style={{ color: '#fff', fontSize: 20 }}>title</text> */}
                                                <hr color='#fff' />
                                            </div>
                                            <div className="col-4">
                                                <div style={styles.dot_btn}>
                                                    <label style={{ color: '#fff', fontSize: 15, padding: 5 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                    <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ height: 380, fontSize: 20, overflowY: 'auto' }}>
                                            <h5 style={{ color: '#fff', paddingTop: 15 }}> {this.state.title}</h5>
                                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', paddingTop: 30 }}>

                                                <DragDropContext onDragEnd={this.onDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(droppableProvided, droppableSnapshot) => (
                                                            <div
                                                                ref={droppableProvided.innerRef}
                                                                style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                            >
                                                                {this.state.reorder_data.map((item, index) => (
                                                                    <Draggable isDragDisabled={!this.state.answer_popup} key={item.reorder_id} draggableId={item.reorder_id} index={index}>
                                                                        {(draggableProvided, draggableSnapshot) => (
                                                                            <div
                                                                                ref={draggableProvided.innerRef}
                                                                                {...draggableProvided.draggableProps}
                                                                                {...draggableProvided.dragHandleProps}
                                                                                style={getItemStyle(
                                                                                    draggableSnapshot.isDragging,
                                                                                    draggableProvided.draggableProps.style
                                                                                )}
                                                                            >
                                                                                {/* <Icon icon={navicon} size={20} className="text-left" /> */}
                                                                                {item.options}
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {droppableProvided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>

                                        <div>
                                            <hr color='#fff' />
                                            <div className="text-center">
                                                <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleAnswerpopup() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                    {this.state.done_button_state ? this.state.prompt : this.state.done_text}
                                                </button>
                                            </div>
                                            <div style={{ backgroundColor: '#fff', visibility: this.state.answer_popup ? "hidden" : "visible", marginTop: -200, position: 'relative', marginLeft: -15, marginRight: -15, padding: 15, borderTop: 'solid', borderTopColor: 'rgba(0,0,0,0.4)', borderTopWidth: 5 }}>
                                                <div style={{ overflowY: 'auto', height: 95 }}>
                                                    <p className="text-left"><strong>{this.state.answer_reinforcement}</strong></p>
                                                    <div className="text-left">
                                                        <p>{this.state.answerText}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    <div className="col-6">
                                                        <Button style={styles.continue_button} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} block>Continue</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                        <div>
                                            <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                <div className="text-center">
                                                    <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                        Review last slide
                                                </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                        Restart lesson
                                                </button>
                                                </div>
                                                {/* <div className="text-center">
                                                <button className="btn" style={styles.previewbutton}>
                                                    Exit lesson
                                                </button>
                                            </div> */}
                                                <div className="text-center">
                                                    <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                            <div className="col-4">
                                <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                            </div>
                            <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                <span >Slide {this.props.current_index + 1}</span>
                            </div>
                            <div className="col-4">
                                <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                    <div className="card-sm" >
                        {/* <form> */}
                        <div className="card-content block-xl" style={{ height: 550, overflowY: 'scroll', overflowX: 'scroll' }}>
                            <h5 className="text-left" style={{ paddingBottom: 10 }}>Reorder</h5>
                            <div className="text-left">
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text}>Title*</span>
                                    </div>
                                    <input type="text" className="form-control" id="title" value={this.state.title} minLength={2} maxLength={250} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                    <label className="input-error error_title"></label>
                                </div>
                                <hr />
                                <div>
                                    <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                        <div>
                                            <div eventKey="0">
                                                <h5>Answers</h5>
                                            </div>
                                            <Accordion.Collapse eventKey="0">
                                                <div>
                                                    {this.state.items.map((item, i) => (
                                                        <div key={i} style={{ padding: 10, marginBottom: 10, backgroundColor: '#fff' }}>
                                                            <div className="form-group">
                                                                <div className="input-group-prepend" style={styles.textbox}>
                                                                    <span className="input-group-text" style={styles.text}>Content*</span>
                                                                </div>
                                                                <input type="text" className="form-control" id={"content" + i} minLength={2} maxLength={100} value={item.options} onChange={this.handleChangeContent.bind(this, i)} onBlur={this.contentValidation.bind(this, i)} />
                                                                <label className={"input-error error_content" + i}></label>
                                                            </div>
                                                            <div>
                                                                <Button variant="default" disabled={this.state.items.length == 2} style={{ backgroundColor: '#E6EBF0', cursor: this.state.items.length == 2 ? 'no-drop' : 'pointer' }} block onClick={this.handleSectionStatusPopupOpen.bind(this, i)}>
                                                                    <Icon size={20} icon={trashO} style={{ paddingRight: 10 }} />
                                                                        Remove this item
                                                                    </Button>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <Modal size="sm" show={this.state.PublishedSectionPopup} onHide={this.handlePublishedSectionPopup}>
                                                        <Modal.Body>Are you sure you want to remove this?</Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={this.handlePublishedSectionPopup}>No</Button>
                                                            <Button style={styles.confirmButton} onClick={() => this.Remove()}>Yes</Button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    <div style={{ marginTop: 10, color: '#fff' }}>
                                                        <Button variant="default" disabled={this.state.items.length == 6} onClick={this.Add.bind(this)} style={{ backgroundColor: '#223042', color: '#fff', cursor: this.state.items.length == 6 ? 'no-drop' : 'pointer' }} block >
                                                            <Icon size={20} icon={plus} style={{ paddingRight: 10, color: '#fff' }} />
                                                                Add a item
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </div>
                                    </Accordion>
                                </div>
                                <hr />
                                <div>
                                    <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                        <div>
                                            <div as={Card.Body} eventKey="0">
                                                <h5>Answer</h5>
                                            </div>
                                            <Accordion.Collapse eventKey="0">
                                                <div>
                                                    <div className="form-group">
                                                        <div className="input-group-prepend" style={styles.textbox}>
                                                            <span className="input-group-text" style={styles.text} >Answer Text</span>
                                                        </div>
                                                        <textarea type="text" className="form-control" id="answer_text" maxLength={150} value={this.state.answerText} onChange={this.handleChangeAnswerText} onBlur={this.answerTextValidation} />
                                                        <label className="input-error error_answer_text"></label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group-prepend" style={styles.textbox}>
                                                            <span className="input-group-text" style={styles.text}>Correct Reinforcement*</span>
                                                        </div>
                                                        <input type="text" className="form-control" id="correct_reinforcement" value={this.state.correctAns} minLength={2} maxLength={50} onChange={this.handleChangeCorrectAns} onBlur={this.correct_reinforcementValidation} />
                                                        <label className="input-error error_correct_reinforcement"></label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group-prepend" style={styles.textbox}>
                                                            <span className="input-group-text" style={styles.text}>Incorrect Reinforcement*</span>
                                                        </div>
                                                        <input type="text" className="form-control" id="incorrect_reinforcement" value={this.state.incorrectAns} minLength={2} maxLength={50} onChange={this.handleChangeIncorrectAns} onBlur={this.incorrect_reinforcementValidation} />
                                                        <label className="input-error error_incorrect_reinforcement"></label>
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </div>
                                    </Accordion>
                                </div>
                                <hr />
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text}>Prompt*</span>
                                    </div>
                                    <input type="text" className="form-control" id="prompt" value={this.state.prompt} minLength={2} maxLength={50} onChange={this.handleChangePrompt} onBlur={this.promptValidation} />
                                    <label className="input-error error_prompt"></label>
                                </div>
                                <div className="form-group">
                                    <div className="input-group-prepend" style={styles.textbox}>
                                        <span className="input-group-text" style={styles.text}>Done text*</span>
                                    </div>
                                    <input type="text" className="form-control" id="done_text" value={this.state.done_text} minLength={2} maxLength={30} onChange={this.handleChangeDoneText} onBlur={this.done_textValidation} />
                                    <label className="input-error error_done_text"></label>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Button className="btn btn-dark" onClick={this.handleSubmit} style={{ width: 150 }}>Save</Button>
                            <div id="snackbar"></div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        )
    }
}