import React from 'react'
import { Icon } from 'react-icons-kit'
import { smallDown, smallRight, smallLeft } from 'react-icons-kit/entypo'
import { pencil } from 'react-icons-kit/fa/pencil'
import { Accordion, Card, Button, Media } from 'react-bootstrap'
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: 300,
    },
    margin: {
        height: theme.spacing(4),
        margin: 0 - 8,

    },
}));

export default class Slider_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lebels: [],
            minVal: '',
            maxVal: '',
            labelCount: '',
            increment: '',
            done_button_name: this.props.data.prompt,
            done_text: this.props.data.done_text,
            done_button_state: true,
            answer_popup: true,
            selected_answer:'',
            answer_reinforcement: '',
            correct_value: this.props.data.correct_value,
            title: this.props.data.title
        }
    }

    handleAnswerpopup = () => {
        this.setState({ answer_popup: false })
        if (this.state.selected_answer == this.state.correct_value) {
            this.setState({ answer_reinforcement: this.props.data.correct_reinforcement })
        } else {
            this.setState({ answer_reinforcement: this.props.data.incorrect_reinforcement })
        }
    }

    handleChange = (event, value) => {
        this.setState({
            done_button_state: false,
            done_button_name: this.state.done_text,
            selected_answer: value
        })

    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    componentDidMount() {
        let minVal = parseInt(this.props.data.min_value);
        let maxVal = parseInt(this.props.data.max_value);
        let labelCount = parseInt(this.props.data.label);
        let increment = parseInt(this.props.data.increment);

        let diff = maxVal - minVal;
        let dispLable = labelCount - 1;
        let incrLabelValue = diff / dispLable;
        let lebelData = [];
        lebelData.push({ value: minVal, label: minVal })
        let newVal = minVal + incrLabelValue;

        for (let i = 0; i < dispLable - 1; i++) {

            lebelData.push({ value: Math.round(newVal), label: Math.round(newVal) })
            newVal = newVal + incrLabelValue;
        }
        lebelData.push({ value: maxVal, label: maxVal })
        this.setState({ minVal: minVal, maxVal: maxVal, labelCount: labelCount, increment: increment, lebels: lebelData });

    }
    render() {
        return (
            <div>
                <div style={{ height: 340, paddingLeft: 60, paddingRight: 60 }}>
                    <div style={{ marginBottom: 50, paddingTop: 60 }}>
                        <h4 className="text-center" style={{ color: '#fff', overflowWrap: 'anywhere' }}>{this.state.title}</h4>
                    </div>
                    <div style={useStyles.root} >
                        <div style={useStyles.margin} />
                        <Slider
                            min={this.state.minVal}
                            max={this.state.maxVal}
                            step={this.state.increment}
                            marks={this.state.lebels}
                            style={{ color: '#fff' }}
                            onChange={this.handleChange}
                            disabled={!this.state.answer_popup}
                        />
                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleAnswerpopup() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                            {this.state.done_button_name}
                        </button>
                    </div>
                    <div style={{ backgroundColor: '#fff', visibility: this.state.answer_popup ? "hidden" : "visible", marginTop: -200, position: 'relative', marginLeft: -15, marginRight: -16, padding: 15, borderTop: 'solid', borderTopColor: 'rgba(0,0,0,0.4)', borderTopWidth: 5 }}>
                        <div style={{ height: 85, overflowY: 'auto' }}>
                            <p><strong>{this.state.answer_reinforcement}</strong></p>
                            <div>
                                <p>{this.props.data.answer_text}</p>
                            </div>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-6">
                                <Button style={styles.continue_button} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} block>Continue</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    radio: {
        borderWidth: 1,
        backgroundColor: '#fff',
        margin: 5,
        paddingLeft: 20,
        borderRadius: 5,
        fontSize: 15,
        paddingTop: 5
    },
    continue_button: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.2)',
        padding: 10,
        backgroundColor: '#fff',
        color: '#000000'
    }
}