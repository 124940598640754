import React from "react";
// import ReactDOM from "react-dom";
// import CanvasDraw from "react-canvas-draw";

// import "./Circle_the_answer.css";

var mouse = { x: 0, y: 0 };
var last_mouse = { x: 0, y: 0 };
class Circle_the_answer extends React.Component {
  constructor() {
    super();
    this.state = {
      options: [{
        text: "Answer 1",
        x: 55,
        y: 50,
      },
      {
        text: "Answer 2",
        x: 55,
        y: 100,
      },
      {
        text: "Answer 3",
        x: 55,
        y: 150,
      }],
      traversedX: [],
      traversedY: []
    };
    this.done = this.done.bind(this);

  }
  done() {
    let sX = Math.min(...this.state.traversedX);
    let lX = Math.max(...this.state.traversedX);
    let sY = Math.min(...this.state.traversedY);
    let lY = Math.max(...this.state.traversedY);

    let selectedAns = this.state.options.map((opt) => {

      if (opt.x > sX && opt.x < lX && opt.y > sY && opt.y < lY) {
        return opt;
      }
    })
    var canvas = document.querySelector('#paint');
    var ctx = canvas.getContext('2d');


    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.setState({ traversedX: [], traversedY: [] });
    this.state.options.forEach(option => {
      ctx.fillText(option.text, option.x, option.y);
    });

  }
  componentDidMount() {
    var canvas = document.querySelector('#paint');
    var ctx = canvas.getContext('2d');

    var sketch = document.querySelector('#sketch');
    var sketch_style = getComputedStyle(sketch);
    canvas.width = 200;
    canvas.height = 200;



    /* Mouse Capturing Work */
    canvas.addEventListener('mousemove', function (e) {

      last_mouse.x = mouse.x;
      last_mouse.y = mouse.y;

      mouse.x = e.pageX - this.offsetLeft;
      mouse.y = e.pageY - this.offsetTop;
    }, false);


    /* Drawing on Paint App */
    ctx.lineWidth = 1;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'blue';
    ctx.font = "18px Verdana";
    ctx.fillStyle = "#fff"

    canvas.addEventListener('mousedown', function (e) {
      canvas.addEventListener('mousemove', onPaint, false);
    }, false);

    canvas.addEventListener('mouseup', function () {
      canvas.removeEventListener('mousemove', onPaint, false);
    }, false);
    let __this = this;
    var onPaint = function () {
      __this.state.traversedX.push(last_mouse.x);
      __this.state.traversedY.push(last_mouse.y);


      ctx.beginPath();
      ctx.moveTo(last_mouse.x, last_mouse.y);
      ctx.lineTo(mouse.x, mouse.y);
      ctx.closePath();
      ctx.stroke();
    };
    this.state.options.forEach(option => {
      ctx.fillText(option.text, option.x, option.y);
    });


  }
  paint() {

  }
  render() {
    return (
      <div className="draw-container">

        <div id="sketch">
          <canvas id="paint"></canvas>

        </div>
        <button onClick={this.done}>I'mDone</button>
      </div>
    );
  }
}
export default Circle_the_answer;