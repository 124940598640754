import React from 'react'
import { Accordion, Button, Card, Table } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { cross } from 'react-icons-kit/icomoon/'
import API from '../Components/Global_API'
import Router from '../Router';

const styles = {
    inputDiv: {
        paddingBottom: 10
    },
    buttons: {
        fontWeight: '600'
    },
    clearFilterIcon: {
        padding: 5,
        color: 'rgb(138, 141, 150)'
    },
    inputField: {
        height: 40
    },
    inputAssessor_nameList: {
        padding: 10,
        borderBottom: '1px solid rgba(153, 153, 153, 0.32)',
        cursor: 'pointer'
    },
    activeinputAssessor_name: {
        padding: 10,
        borderBottom: '1px solid rgba(153, 153, 153, 0.32)',
        cursor: 'pointer'
    },
    inputAssessor_name: {
        border: 'solid',
        borderWidth: 1,
        borderColor: '#999',
        borderTopWidth: 0,
        listStyle: 'none',
        marginTop: 0,
        maxHeight: 150,
        overflowY: 'auto',
        paddingLeft: 0,
        backgroundColor: "#fff"
    },
}

export default class All_assessor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            start_date: '',
            end_date: '',
            data: [],
            region_data: [],
            search_region: '',
            assessor_data: [],
            showAssessorList: false,
            all_assessor: [],
            user_message: "Not assessor found",
            region_filter_data: [],
            search_data: [],
            dataFound: false
        }

        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    get_assessor() {
        let role = JSON.stringify({
            role: "assessor"
        })
        fetch(API + 'get_all_users.php', {
            method: 'POST',
            body: role
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            this.setState({ dataFound: true })
            if (responseJsonFromServer.result == "success") {
                this.setState({ data: responseJsonFromServer.users_data, all_assessor: responseJsonFromServer.users_data, region_filter_data: responseJsonFromServer.users_data, search_data: responseJsonFromServer.users_data })
            } else {

            }

        }).catch((error) => {
            console.warn(error.message);
        });
    }

    componentDidMount() {
        this.get_assessor();

        fetch(API + 'get_all_data.php').then((response) => response.json()).then((responseJsonFromServer) => {

            if (responseJsonFromServer.result == "success") {
                this.setState({
                    region_data: responseJsonFromServer.regions_data,
                })
            } else {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                x.innerHTML = responseJsonFromServer.msg;
            }

        }).catch((error) => {
            console.warn(error.message);
        });
    }

    handleIndividualAssessor = (item) => {
        this.props.history.push('/edit_assessor', { assessor: item })
    }

    handleCreateAssessor = () => {
        this.props.history.push('/add_assessor')
    }

    handleClearRegion = (e) => {
        this.setState({ search_region: '' })
    }

    handleSearchRegion = (e) => {
        this.setState({ search_region: e.target.value })
            let region_data = []
            if (e.target.value.length > 1) {
                let assessor = this.state.all_assessor
                let filter_assessor = assessor.filter((item) => {
                    return item.regions == e.target.value;
                });
                region_data = filter_assessor
            } else {
                region_data = this.state.all_assessor
            }
            this.setState({region_filter_data: region_data})

            Array.prototype.intersection = function (arr) {
                return this.filter(function (e) {
                    return arr.indexOf(e) > -1;
                });
            };
    
            let filter_data = this.state.search_data.intersection(region_data)
            this.setState({ data: filter_data })
    }

    handleClearSearch = () => {
        this.setState({ search: '', showAssessorList: false, search_data : this.state.all_assessor })

        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.all_assessor.intersection(this.state.region_filter_data)
        this.setState({ data: filter_data })
    }

    handleSearch = (e) => {
        this.setState({
            search: e.target.value
        })
        
        let assessor_data = []
            if (e.target.value.length == 0) {
                assessor_data = this.state.all_assessor 
            } else {
                let Assessor = this.state.all_assessor
                let filterdCourses = Assessor.filter((item) => {
                    return (item.middle_name ? item.first_name.toLowerCase() + ' ' + item.middle_name.toLowerCase() + ' ' + item.last_name.toLowerCase() : item.first_name.toLowerCase() + ' ' + item.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) || item.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.middle_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.last_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.email.toLowerCase().includes(e.target.value.toLowerCase()) || item.contact.includes(e.target.value);
                });
                assessor_data = filterdCourses
            }
            this.setState({ search_data: assessor_data });

            Array.prototype.intersection = function (arr) {
                return this.filter(function (e) {
                    return arr.indexOf(e) > -1;
                });
            };
    
            let filter_data = this.state.region_filter_data.intersection(assessor_data)
            this.setState({ data: filter_data })

    }



    render() {
        if (this.state.dataFound) {
            return (
                <div>
                    <Router />
                    <div style={{ backgroundColor: '#EEF2F6', minHeight: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 80, paddingBottom: 20, paddingRight: '2%', paddingLeft: '2%' }}>
                            <div className="row" style={{ marginTop: -10, marginBottom: -10 }}>
                                <Accordion style={{ width: '100%' }} defaultActiveKey="0">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-6 pl-2 dashboard-header"></div>
                                        <div className="col-md-4 col-sm-6 col-xs-6 text-center pt-2" >
                                            <Accordion.Toggle as={Button} style={{ backgroundColor: '#ebeced', cursor: 'default' }} variant="default" eventKey="0">
                                                Filter by <span style={{ fontWeight: '700' }}>assessor</span>
                                            </Accordion.Toggle>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xs-6 text-right pt-2" style={{ paddingRight: 35 }}>
                                            <Button variant="primary" onClick={this.handleCreateAssessor} style={styles.buttons}>Create new assessor</Button>
                                        </div>
                                    </div>

                                    <div eventKey="0">
                                        <Card.Body >
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 pr-lg-4 pr-md-4 pr-sm-3">
                                                            <div className="row">
                                                                <div className="col-12 p-0 pb-3">
                                                                    <h6>Region</h6>
                                                                    <select className="form-control" value={this.state.search_region} onChange={this.handleSearchRegion} placeholder="search by region" style={styles.inputField}>
                                                                        <option value=''>Any</option>
                                                                        {this.state.region_data.map(item =>
                                                                            <option value={item.regions}>{item.regions}</option>
                                                                        )}
                                                                    </select>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 pl-lg-4 pl-md-4 pl-sm-3 pt-sm-3 pt-xs-3 pt-lg-0 pt-md-0 pl-xs-0">
                                                            <div className="row">
                                                                <div className="col-11 p-0" style={styles.inputDiv}>
                                                                    <h6>Search</h6>
                                                                    <input type="text" placeholder="Search by name, email and contact number" value={this.state.search} onChange={this.handleSearch} className="form-control" style={styles.inputField} />

                                                                </div>
                                                                <div className="col-1 p-0 text-center" style={{ border: '1px solid rgb(206, 212, 218)', borderRadius: 3, cursor: 'pointer', height: 40, marginTop: 27 }} onClick={this.handleClearSearch}>
                                                                    <Icon icon={cross} size="20" style={styles.clearFilterIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </div>
                                </Accordion>
                            </div>
                        </div>

                        <div className="body-container" style={{ paddingTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingRight: '2%', paddingLeft: '2%' }}>
                            <div className="col-12 row p-1">

                                {this.state.data.length == 0 ?
                                    <div className="col-12 text-center">
                                        <h4>No user found</h4>
                                    </div> :
                                    <Table responsive >
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Assessor Name</th>
                                                <th>Region</th>
                                                <th>Email</th>
                                                <th>Contact number</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map(item =>
                                                <tr>
                                                    <td>{item.id}</td>
                                                    <td>{item.first_name + ' ' + item.middle_name + ' ' + item.last_name}</td>
                                                    <td>{item.regions}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.contact}</td>
                                                    <td><Button variant="primary" style={styles.buttons} block onClick={() => this.handleIndividualAssessor(item)} >View</Button></td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </Table>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ minHeight: '100vh', backgroundColor: '#EEF2F6' }}>
                    <Router />
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 80 }}>
                        <img src={require('../../assets/Loader.gif')} style={{ width: 100, height: 100 }} />
                    </div>
                </div>
            )
        }
    }
}