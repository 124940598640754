import React from 'react'
import Memory from './Memory'

export default class Memory_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            word: this.props.data.memory_data,
            Timer: this.props.data.time_limit,
            countdown: '3',
            title: this.props.data.title,
            done_button_state: true,
            score: 0
        }
    }

    componentDidMount() {
        this.interval = setInterval(
            () => this.setState((prevState) => ({ countdown: prevState.countdown - 1 })),
            1000
        );
    }

    componentDidUpdate() {
        if (this.state.countdown === -1) {
            clearInterval(this.interval);
        };

    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    handleDisableButton = (done_button_state) => {
        this.setState({ done_button_state: done_button_state, countdown: '3'  })
    }

    handleScore = (score) => {
        this.setState({ score: score })
    }

    handleCountDown = () => {
        this.setState({done_button_state: true, score: 0})
        this.componentDidMount()
    }

    render() {
        return (
            this.state.done_button_state ?
                <div>
                    <div style={{ height: 340, overflowX: 'auto' }}>
                        <Memory time_limit={this.state.Timer} title={this.state.title} nextQuestion={this.props.nextQuestion} memory_array={this.state.word} handleDisableButton={this.handleDisableButton} handleScore={this.handleScore} />
                    </div>
                    <div style={{ height: 550, fontSize: 15, color: '#fff', position: 'absolute', top: -30, left: -30, margin: 30, zIndex: 15, width: '100%', visibility: this.state.countdown == '-1' ? "hidden" : "visible" }}>
                        <div style={{ height: 500, paddingTop: 35, display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>
                            <div className="text-center" style={{ justifyContent: 'center', display: 'flex' }}>
                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.48)', marginBottom: 30, color: '#fff', justifyContent: 'center', display: 'flex', flexDirection: 'column', borderWidth: 1, borderColor: '#fff', fontWeight: 'bold', height: 100, width: 100, padding: "10px 30px", fontSize: 50, borderRadius: 50 }}>
                                    <h4>{this.state.countdown == '0' ? 'GO' : this.state.countdown}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr color='#fff' />
                        <div className="text-center">
                            <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                {this.state.done_button_state ? this.props.data.prompt : this.props.data.done_text}
                            </button>
                        </div>
                    </div>
                </div> : 
                <div>
                    <div style={{ height: 340, color: '#fff', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <h5 className="text-center">The memory game</h5>
                        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.35)', padding: 5, margin: '10px 0', borderRadius: 3 }}>
                            <h6 className="text-center">YOUR SCORE</h6>
                            <h6 className="text-center">{this.state.score}</h6>
                        </div>
                        <button className="btn" onClick={() => { this.handleCountDown() }} style={{ color: '#000000', backgroundColor: '#fff', fontWeight: '600' }}>
                            Play again
                        </button>
                    </div>
                    <div>
                        <hr color='#fff' />
                        <div className="text-center">
                            <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                {this.props.data.done_text}
                            </button>
                        </div>
                    </div>
                </div>
        )
    }
}

const styles = {
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 20,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold',
        zIndex: 100
    },

}