import React from 'react'
import Crossword from './Crossword'

export default class Crossword_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            correct_reinforcement: this.props.data.correct_reinforcement,
            crossword_data: this.props.data.crossword_data,
            done_text: this.props.data.done_text,
            incorrect_reinforcement: this.props.data.incorrect_reinforcement,
            lesson_id: this.props.data.lesson_id,
            prompt: this.props.data.prompt,
            question_id: this.props.data.question_id,
            question_type: this.props.data.question_type,
            title: this.props.data.title,
            time_limit: this.props.data.time_limit
        }
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    render() {
        return (

            <div>

                <div style={{ height: 290,overflowY: 'auto' }}>
                    <Crossword title={this.state.title} crossword_data={this.state.crossword_data} />
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={styles.done_btn}>
                            {this.props.data.done_text}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },

}