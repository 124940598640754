const FLAG_SELECTOR_OPTION_LIST = [
    {
        id: "44",
        name: "GB",
        displayText: "GB(44)",
        // locale: "en-US",
        flag: require('../../assets/flags/gb.svg')
    },
    {
        id: "93",
        name: "AF",
        displayText: "AF(93)",
        // locale: "en-US",
        flag: require('../../assets/flags/af.svg')
    },
    {
        id: "355",
        name: "AL",
        displayText: "AL(355)",
        // locale: "en-US",
        flag: require('../../assets/flags/al.svg')
    },
    {
        id: "213",
        name: "DZ",
        displayText: "DZ(213)",
        // locale: "en-US",
        flag: require('../../assets/flags/dz.svg')
    },
    {
        id: "376",
        name: "AD",
        displayText: "AD(376)",
        // locale: "en-US",
        flag: require('../../assets/flags/ad.svg')
    },
    {
        id: "244",
        name: "AO",
        displayText: "AO(244)",
        // locale: "en-US",
        flag: require('../../assets/flags/ao.svg')
    },
    {
        id: "1-684",
        name: "AS",
        displayText: "AS(1-684)",
        // locale: "en-US",
        flag: require('../../assets/flags/as.svg')
    },
    {
        id: "54",
        name: "AR",
        displayText: "AR(54)",
        // locale: "en-US",
        flag: require('../../assets/flags/ar.svg')
    },
    {
        id: "374",
        name: "AM",
        displayText: "AM(374)",
        // locale: "en-US",
        flag: require('../../assets/flags/am.svg')
    },
    {
        id: "297",
        name: "AW",
        displayText: "AW(297)",
        // locale: "en-US",
        flag: require('../../assets/flags/aw.svg')
    },
    {
        id: "61",
        name: "AU",
        displayText: "AU(61)",
        // locale: "en-US",
        flag: require('../../assets/flags/au.svg')
    },
    {
        id: "43",
        name: "AT",
        displayText: "AT(43)",
        // locale: "en-US",
        flag: require('../../assets/flags/at.svg')
    },
    {
        id: "994",
        name: "AZ",
        displayText: "AZ(994)",
        // locale: "en-US",
        flag: require('../../assets/flags/az.svg')
    },
    {
        id: "1",
        name: "BS",
        displayText: "BS(1)",
        // locale: "en-US",
        flag: require('../../assets/flags/bs.svg')
    },












    {
        id: "1",
        name: "US",
        displayText: "US(1)",
        // locale: "en-US",
        flag: require('../../assets/flags/us.svg')
    },
    {
        id: "86",
        name: "CN",
        displayText: "CN(86)",
        // locale: "zh-CN",
        flag: require('../../assets/flags/cn.svg')
    },
    {
        id: "91",
        name: "IN",
        displayText: "IN(91)",
        // locale: "zh-CN",
        flag: require('../../assets/flags/in.svg')
    },
    
];
export default FLAG_SELECTOR_OPTION_LIST