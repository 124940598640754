import React from 'react'

export default class Exercise extends React.Component {
    constructor() {
        super();
        this.state = {
            component: '',
            exercise_type: ''
        }
    }

    handleComponent = (e) => {
        this.setState({ component: e.target.value })
    }

    handleExerciseType = (e) => {
        console.log("e.target.value", e.target.value);

        this.setState({ exercise_type: e.target.value })
    }

    exercise_type_render() {
        if (this.state.component == 'CV') {
            return (
                <>
                    <label className="mt-3">Exercise Type</label>
                    <select value={this.state.exercise_type} class="form-control" onChange={this.handleExerciseType}>
                        <option value=''>Select</option>
                        <option value="CV- Warm Up">CV- Warm Up</option>
                        <option value="CV- Main CV">CV- Main CV</option>
                        <option value="CV- Cool down">CV- Cool down</option>
                    </select>
                    <label className="mt-3">Activity</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Equipment</label>
                    <select class="form-control">
                        <option value=''>Select</option>
                        <option value="Rowing machine">Rowing machine</option>
                        <option value="Upright cycle">Upright cycle</option>
                        <option value="Recumbent cycle">Recumbent cycle</option>
                        <option value="Treadmill">Treadmill</option>
                        <option value="Cross-trainer">Cross-trainer</option>
                        <option value="Stepper">Stepper</option>
                    </select>
                    <label className="mt-3">Duration</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">%MHR</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">RPE</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Training zone</label>
                    <input type='text' className="form-control" />
                </>
            )
        } else if (this.state.component == 'RE') {
            return (
                <>
                    <label className="mt-3">Exercise Type</label>
                    <select value={this.state.exercise_type} class="form-control" onChange={this.handleExerciseType}>
                        <option value=''>Select</option>
                        <option value="FW- Lower body">FW- Lower body</option>
                        <option value="FW- Abdominal and lower back">FW- Abdominal and lower back</option>
                        <option value="FW- Upper body">FW- Upper body</option>
                        <option value="FW- Arms">FW- Arms</option>

                        <option value="RM- Lower body">RM- Lower body</option>
                        <option value="RM- Abdominal and lower back">RM- Abdominal and lower back</option>
                        <option value="RM- Upper body">RM- Upper body</option>
                        <option value="RM- Arms">RM- Arms</option>

                        <option value="BW- Lower body">BW- Lower body</option>
                        <option value="BW- Abdominal and lower back">BW- Abdominal and lower back</option>
                        <option value="BW- Upper body">BW- Upper body</option>
                        <option value="BW- Arms">BW- Arms</option>
                    </select>
                </>
            )
        } else {
            return (
                <div>

                </div>
            )
        }
    }

    activity_render() {
        // if(this.state.component == 'CV' || this.state.component == 'RE'){
         if ((this.state.exercise_type == 'FW- Lower body')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Lunge">Lunge</option>
                        <option value="Deadlift">Deadlift</option>
                        <option value="Squat">Squat</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'FW- Abdominal and lower back')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Deadlift">Deadlift</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'FW- Upper body')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Bench press">Bench press</option>
                        <option value="Lying flyes">Lying flyes</option>
                        <option value="Single arm row">Single arm row</option>
                        <option value="Prone flyes">Prone flyes</option>
                        <option value="Dumb bell shoulder press">Dumb bell shoulder press</option>
                        <option value="Upright row">Upright row</option>
                        <option value="Dumb bell lateral raise">Dumb bell lateral raise</option>
                        <option value="Front raise">Front raise</option>
                        <option value="Bent arm pullover">Bent arm pullover</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'FW- Arms')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Lying triceps Extension">Lying triceps Extension</option>
                        <option value="Supine tricep press">Supine tricep press</option>
                        <option value="Dumbbell / barbell curl">Dumbbell / barbell curl</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        }

        else if ((this.state.exercise_type == 'RM- Lower body')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Leg extension">Leg extension</option>
                        <option value="Leg curl">Leg curl</option>
                        <option value="Leg press">Leg press</option>
                        <option value="Seated adductor">Seated adductor</option>
                        <option value="Seated abductor">Seated abductor</option>
                        <option value="Total hip machine">Total hip machine</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'RM- Abdominal and lower back')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Abdominal machine">Abdominal machine</option>
                        <option value="Torso twister">Torso twister</option>
                        <option value="Back extension / Lower back machine">Back extension / Lower back machine</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'RM- Upper body')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Seated Chest press (varied grip) / pec dec">Seated Chest press (varied grip) / pec dec</option>
                        <option value="Seated row/ lever row">Seated row/ lever row</option>
                        <option value="Shoulder press">Shoulder press</option>
                        <option value="Lat pull down / Assisted pull up">Lat pull down / Assisted pull up</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'RM- Arms')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Tricep pushdown">Tricep pushdown</option>
                        <option value="Tricep press">Tricep press</option>
                        <option value="Cable bicep curl">Cable bicep curl</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        }

        else if ((this.state.exercise_type == 'BW- Lower body')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Lunge">Lunge</option>
                        <option value="Squat">Squat</option>
                        <option value="Inner thigh raises">Inner thigh raises</option>
                        <option value="Outer thigh raises">Outer thigh raises</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'BW- Abdominal and lower back')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Ab curl">Ab curl</option>
                        <option value="Oblique curl">Oblique curl</option>
                        <option value="Crunches">Crunches</option>
                        <option value="Ab plank">Ab plank</option>
                        <option value="Side plank">Side plank</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'BW- Upper body')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Press up">Press up</option>
                        <option value="Pull up">Pull up</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        } else if ((this.state.exercise_type == 'BW- Arms')&&(this.state.component == 'CV' || this.state.component == 'RE')) {
            return (
                <div>
                    <label className="mt-3">Activity</label>
                    <select className="form-control">
                        <option value="">Select</option>
                        <option value="Tricep dip">Tricep dip</option>
                        <option value="Close grip press up">Close grip press up</option>
                    </select>
                    <label className="mt-3">Number of repetitions</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Number of sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">Recovery between sets</label>
                    <input type='text' className="form-control" />
                    <label className="mt-3">% 1 RM</label>
                    <input type='text' className="form-control" />
                </div>
            )
        }

        else {
            return (
                <div>

                </div>
            )
        }

    }

    render() {
        return (
            <div style={{ backgroundColor: '#fff', height: window.innerHeight }}>
                <div class="container">
                    <div class="row pt-3">
                        <div class="col-sm-12 col-md-12">
                            <label className="mt-3">Component</label>
                            <select value={this.state.component} class="form-control" onChange={this.handleComponent}>
                                <option value=''>Select</option>
                                <option value="CV">CV</option>
                                <option value="RE">Resistance exercise</option>
                            </select>
                            {this.exercise_type_render()}
                            {this.activity_render()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}