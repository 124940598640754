import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import LoginScreen from './Screens/LoginScreen';
import CoursesScreen from './Screens/CoursesScreen'
import Create_new_lesson from './Screens/Create_new_lesson'
import Edit_Content from './Screens/Edit_Content';
import Create_Course from './Screens/Create_Course';
import UnitScreen from './Screens/UnitScreen';
import SectionScreen from './Screens/SectionScreen';
import Account_details from './Screens/Account_setting/Account_details';
import Billing_details from './Screens/Account_setting/Billing_details';
import ForgotScreen from './Screens/ForgotScreen';
import Logout from './Screens/Logout';
import Router from './Screens/Router';
import PageNotFound from './Screens/Components/PageNotFound_error';
import Assessment from './Screens/Assessment';
import Assessor from './Screens/Dashboard/Assessor';
import Learner from './Screens/Dashboard/Learner';
import Course_type from './Screens/Dashboard/Course_type';
import IQA from './Screens/Dashboard/IQA';
import Report from './Screens/Report';
import Individual_learner from './Screens/Dashboard/Individual_learner';
import AdminCalendar from './Screens/Calendar';
import All_IQA from './Screens/Dashboard/All_IQA';
import All_assessor from './Screens/Dashboard/All_assessor';
import All_learner from './Screens/Dashboard/All_learner';
import Edit_IQA from './Screens/Dashboard/Edit_IQA';
import Edit_Assessor from './Screens/Dashboard/Edit_assessor';
import Edit_Learner from './Screens/Dashboard/Edit_learner';
import Messages from './Screens/Messages'


class Navigation extends React.Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={LoginScreen} exact />
                    <Route path="/Router" component={Router} />
                    <Route path="/CoursesScreen" component={CoursesScreen} />
                    <Route path="/edit_content" component={Edit_Content} />
                    <Route path="/Create_Course" component={Create_Course} />
                    <Route path="/Create_lesson" component={Create_new_lesson} />
                    <Route path="/UnitScreen" component={UnitScreen} />
                    <Route path="/SectionScreen" component={SectionScreen} />
                    <Route path="/Account_details" component={Account_details} />
                    <Route path="/Billing_details" component={Billing_details} />
                    <Route path="/ForgotScreen" component={ForgotScreen} />
                    <Route path="/Logout" component={Logout} />
                    <Route path="/assessment" component={Assessment} />
                    <Route path="/add_assessor" component={Assessor} />
                    <Route path="/add_learner" component={Learner} />
                    <Route path="/course_type" component={Course_type} />
                    <Route path="/add_iqa" component={IQA} />
                    <Route path="/all_iqa" component={All_IQA} />
                    <Route path="/all_assessor" component={All_assessor} />
                    <Route path="/all_learner" component={All_learner} />
                    <Route path="/edit_iqa" component={Edit_IQA} />
                    <Route path="/edit_assessor" component={Edit_Assessor} />
                    <Route path="/edit_learner" component={Edit_Learner} />
                    <Route path="/report" component={Report} />
                    <Route path="/individual_learner" component={Individual_learner} />
                    <Route path="/messages" component={Messages} />
                    <Route path="/calendar" component={AdminCalendar} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Navigation;