import React from 'react'
import { Accordion, Button, Card, Table } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { cross } from 'react-icons-kit/icomoon/'
import API from '../Components/Global_API'
import Router from '../Router';

const styles = {
    inputDiv: {
        paddingBottom: 10,
        paddingRight: 5
    },
    buttons: {
        fontWeight: '600',
    },
    progress_buttons: {
        fontWeight: '600',
        width: 100,
        margin: "0px 15px 10px 15px"
    },
    clearFilterIcon: {
        padding: 5,
        color: 'rgb(138, 141, 150)'
    },
    inputField: {
        height: 40
    }
}

export default class All_learner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            start_date: '',
            end_date: '',
            data: [],
            search_learner_type: '',
            search_course_type: '',
            search_region: '',
            search_payment_plan: '',
            search_assessor: '',
            learner_type_data: [],
            course_type_data: [],
            region_data: [],
            payment_plan_data: [],
            assessor_data: [],
            all_learner: [],
            region_filter_data: [],
            learner_type_filter_data: [],
            course_type_filter_data: [],
            payment_plan_filter_data: [],
            assessor_filter_data: [],
            search_data: [],
            filter_data: [],
            dataFound: false
        }

        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    get_learner() {
        let role = JSON.stringify({
            role: "learner"
        })
        fetch(API + 'get_all_users.php', {
            method: 'POST',
            body: role
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                
                this.setState({
                    data: responseJsonFromServer.users_data,
                    all_learner: responseJsonFromServer.users_data,
                    region_filter_data: responseJsonFromServer.users_data,
                    learner_type_filter_data: responseJsonFromServer.users_data,
                    course_type_filter_data: responseJsonFromServer.users_data,
                    payment_plan_filter_data: responseJsonFromServer.users_data,
                    assessor_filter_data: responseJsonFromServer.users_data,
                    filter_data: responseJsonFromServer.users_data,
                    search_data: responseJsonFromServer.users_data,
                })
            }

        }).catch((error) => {
            console.warn(error.message);
        });
    }

    get_assessor() {
        let role = JSON.stringify({
            role: "assessor"
        })
        fetch(API + 'get_all_users.php', {
            method: 'POST',
            body: role
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            this.setState({ dataFound: true })
            if (responseJsonFromServer.result == "success") {
                this.setState({
                    assessor_data: responseJsonFromServer.users_data,
                })
            }

        }).catch((error) => {
            console.warn(error.message);
        });
    }

    componentDidMount() {
        this.get_learner();
        this.get_assessor();

        fetch(API + 'get_all_data.php').then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {

                this.setState({
                    region_data: responseJsonFromServer.regions_data,
                    learner_type_data: responseJsonFromServer.learner_type_data,
                    course_type_data: responseJsonFromServer.course_type_data,
                    payment_plan_data: responseJsonFromServer.payment_plans_data
                })
            } else {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                x.innerHTML = responseJsonFromServer.msg;
            }

        }).catch((error) => {
            console.warn(error.message);
        });
    }

    handleEditLearner = (item) => {
        this.props.history.push('/edit_learner', { learner: item })
    }

    handleLearnerProgress = (item) => {
        this.props.history.push('/individual_learner', { learner_id: item.id })
        
    }

    handleCreateLearner = () => {
        this.props.history.push('/add_learner')
    }

    handleAllClearFilter = () => {
        this.setState({
            search_learner_type: '',
            search_course_type: '',
            search_region: '',
            search_payment_plan: '',
            search_assessor: '',
            search: '',
            data: this.state.all_learner,
            region_filter_data: this.state.all_learner,
            learner_type_filter_data: this.state.all_learner,
            course_type_filter_data: this.state.all_learner,
            payment_plan_filter_data: this.state.all_learner,
            assessor_filter_data: this.state.all_learner,
            filter_data: this.state.all_learner,
            search_data: this.state.all_learner

        })
    }

    handleApplyFilter = () => {
        if (this.state.search_learner_type.length == 0 && this.state.search_course_type.length == 0 && this.state.search_region == 0 && this.state.search_payment_plan == 0 && this.state.search_assessor == 0) {
            this.setState({ data: this.state.all_learner, filter_data: this.state.all_learner })
        } else {
            Array.prototype.intersection = function (arr) {
                return this.filter(function (e) {
                    return arr.indexOf(e) > -1;
                });
            };
            let filter_data = this.state.learner_type_filter_data.intersection(this.state.course_type_filter_data.intersection(this.state.region_filter_data.intersection(this.state.payment_plan_filter_data.intersection(this.state.assessor_filter_data.intersection(this.state.search_data)))))
            this.setState({ data: filter_data, filter_data: filter_data })
        }
    }

    handleSearch = (e) => {
        this.setState({
            search: e.target.value
        })

        if (this.state.search.length == 0) {
            this.setState({ data: this.state.filter_data, search_data: this.state.filter_data });
        } else {
            let iqa = this.state.filter_data
            let filterdIqa = iqa.filter((item) => {
                return (item.middle_name ? item.first_name.toLowerCase() + ' ' + item.middle_name.toLowerCase() + ' ' + item.last_name.toLowerCase() : item.first_name.toLowerCase() + ' ' + item.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) || item.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.middle_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.last_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.email.toLowerCase().includes(e.target.value.toLowerCase()) || item.contact.toLowerCase().includes(e.target.value.toLowerCase());
            });

            this.setState({ data: filterdIqa, search_data: filterdIqa });
        }
    }

    handleSearchLearnerType = (e) => {
        this.setState({ search_learner_type: e.target.value })

        if (this.state.search_data.length == 0) {
            if (e.target.value.length > 1) {
                let learner = this.state.all_learner
                let filter_learner = learner.filter((item) => {
                    return item.type_of_learner == e.target.value;
                });
                this.setState({ learner_type_filter_data: filter_learner });
            } else {
                this.setState({ learner_type_filter_data: this.state.all_learner })
            }
        } else {
            if (e.target.value.length > 1) {
                let learner = this.state.search_data
                let filter_learner = learner.filter((item) => {
                    return item.type_of_learner == e.target.value;
                });
                this.setState({ learner_type_filter_data: filter_learner });
            } else {
                this.setState({ learner_type_filter_data: this.state.search_data })
            }
        }
    }

    handleSearchCourseType = (e) => {
        this.setState({ search_course_type: e.target.value })

        if (this.state.search_data.length == 0) {
            if (e.target.value.length > 1) {
                let learner = this.state.all_learner
                let filter_learner = learner.filter((item) => {
                    return item.course_type == e.target.value;
                });
                this.setState({ course_type_filter_data: filter_learner });
            } else {
                this.setState({ course_type_filter_data: this.state.all_learner })
            }
        } else {
            if (e.target.value.length > 1) {
                let learner = this.state.search_data
                let filter_learner = learner.filter((item) => {
                    return item.course_type == e.target.value;
                });
                this.setState({ course_type_filter_data: filter_learner });
            } else {
                this.setState({ course_type_filter_data: this.state.search_data })
            }
        }
    }
    handleSearchRegion = (e) => {
        this.setState({ search_region: e.target.value })

        if (this.state.search_data.length == 0) {
            if (e.target.value.length > 1) {
                let learner = this.state.all_learner
                let filter_learner = learner.filter((item) => {
                    return item.regions == e.target.value;
                });
                this.setState({ region_filter_data: filter_learner });
            } else {
                this.setState({ region_filter_data: this.state.all_learner })
            }
        } else {
            if (e.target.value.length > 1) {
                let learner = this.state.search_data
                let filter_learner = learner.filter((item) => {
                    return item.regions == e.target.value;
                });
                this.setState({ region_filter_data: filter_learner });
            } else {
                this.setState({ region_filter_data: this.state.search_data })
            }
        }
    }

    handleSearchPaymentPlan = (e) => {
        this.setState({ search_payment_plan: e.target.value })

        if (this.state.search_data.length == 0) {
            if (e.target.value.length > 1) {
                let learner = this.state.all_learner
                let filter_learner = learner.filter((item) => {
                    return item.payment_plan == e.target.value;
                });
                this.setState({ payment_plan_filter_data: filter_learner });
            } else {
                this.setState({ payment_plan_filter_data: this.state.all_learner })
            }
        } else {
            if (e.target.value.length > 1) {
                let learner = this.state.search_data
                let filter_learner = learner.filter((item) => {
                    return item.payment_plan == e.target.value;
                });
                this.setState({ payment_plan_filter_data: filter_learner });
            } else {
                this.setState({ payment_plan_filter_data: this.state.search_data })
            }
        }
    }


    handleSearchAssessor = (e) => {
        this.setState({ search_assessor: e.target.value })

        if (this.state.search_data.length == 0) {
            if (e.target.value.length > 0) {
                let learner = this.state.all_learner
                let filter_learner = learner.filter((item) => {
                    return item.accessor_id == e.target.value;
                });
                this.setState({ assessor_filter_data: filter_learner });

            } else {
                this.setState({ assessor_filter_data: this.state.all_learner })
            }
        } else {
            if (e.target.value.length > 0) {
                let learner = this.state.search_data
                let filter_learner = learner.filter((item) => {
                    return item.accessor_id == e.target.value;
                });
                this.setState({ assessor_filter_data: filter_learner });
            } else {
                this.setState({ assessor_filter_data: this.state.search_data })
            }
        }
    }


    render() {
        if (this.state.dataFound) {
            return (
                <div>
                    <Router />
                    <div style={{ backgroundColor: '#EEF2F6', minHeight: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingLeft: 20, paddingTop: 80, paddingRight: '2%', paddingLeft: '2%' }}>
                            <div>
                                <div className="row" style={{ marginTop: -10, marginBottom: -10 }}>
                                    <Accordion style={{ width: '100%' }} defaultActiveKey="0">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-4"></div>
                                            <div className="col-md-4 col-sm-6 text-center pt-2" >
                                                <Accordion.Toggle as={Button} style={{ backgroundColor: '#ebeced', cursor: 'default' }} variant="default" eventKey="0">
                                                    Filter by <span style={{ fontWeight: '700' }}>Learner</span>
                                                </Accordion.Toggle>
                                            </div>
                                            <div className="col-md-4 col-sm-6 text-right pr-5 pl-0 pt-2">
                                                <Button variant="primary" onClick={this.handleCreateLearner} style={styles.buttons}>Create new learner</Button>
                                            </div>
                                        </div>

                                        <div eventKey="0">
                                            <Card.Body >
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    <div className="col-12">
                                                        <div className="row" >
                                                            <div className="dropdown-filter">
                                                                <h6>Learner type</h6>
                                                                <select className="form-control" value={this.state.search_learner_type} onChange={this.handleSearchLearnerType} style={styles.inputField}>
                                                                    <option value=''>Any</option>
                                                                    {this.state.learner_type_data.map(item =>
                                                                        <option value={item.learner_type}>{item.learner_type}</option>
                                                                    )}
                                                                </select>
                                                            </div>

                                                            <div className="dropdown-filter">
                                                                <h6>Course type</h6>

                                                                <select className="form-control" value={this.state.search_course_type} onChange={this.handleSearchCourseType} style={styles.inputField}>
                                                                    <option value=''>Any</option>
                                                                    {this.state.course_type_data.map(item =>
                                                                        <option value={item.course_type}>{item.course_type}</option>
                                                                    )}
                                                                </select>
                                                            </div>

                                                            <div className="dropdown-filter">
                                                                <h6>Region</h6>
                                                                <select className="form-control" value={this.state.search_region} onChange={this.handleSearchRegion} style={styles.inputField}>
                                                                    <option value=''>Any</option>
                                                                    {this.state.region_data.map(item =>
                                                                        <option value={item.regions}>{item.regions}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="dropdown-filter">
                                                                <h6>Payment type</h6>
                                                                <select className="form-control" value={this.state.search_payment_plan} onChange={this.handleSearchPaymentPlan} style={styles.inputField}>
                                                                    <option value=''>Any</option>
                                                                    {this.state.payment_plan_data.map(item =>
                                                                        <option value={item.payment_plans}>{item.payment_plans}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="dropdown-filter">
                                                                <h6>Assessor</h6>
                                                                <select className="form-control" value={this.state.search_assessor} onChange={this.handleSearchAssessor} style={styles.inputField} placeholder="Assessor" >
                                                                    <option value=''>Any</option>
                                                                    {this.state.assessor_data.map(item =>
                                                                        <option value={item.id}> {item.email} </option>
                                                                    )}
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className="row pt-3" style={{ justifyContent: 'flex-end' }}>
                                                            <div className="col-lg-5 col-md-5 col-sm-12 pl-2 pr-3 left" style={{ paddingRight: 30, display: 'flex' }}>
                                                                {/* <div className="col-11 p-0" style={styles.inputDiv}> */}
                                                                <input type="text" placeholder="Search by learner name, email and contact number" value={this.state.search} onChange={this.handleSearch} style={styles.inputField} className="form-control" />
                                                                {/* </div> */}
                                                                {/* <div className="col-1 p-0 text-center" style={{ border: '1px solid rgb(206, 212, 218)', height: 40, borderRadius: 3, cursor: 'pointer' }} onClick={this.handleClearSearch}>
                                                                    <Icon icon={cross} size="20" style={styles.clearFilterIcon} />
                                                                </div> */}
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 col-sm-6 pl-2 right apply-button">
                                                                <Button style={styles.buttons} block onClick={this.handleApplyFilter}>Apply filter</Button>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 col-sm-6 pl-2 right clear-button">
                                                                <Button style={styles.buttons} block onClick={this.handleAllClearFilter}>Clear all</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                        </div>

                        <div className="body-container" style={{ paddingTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingLeft: '2%', paddingRight: '2%' }}>
                            <div className="col-12 row">

                                {this.state.data.length == 0 ?
                                    <div className="col-12 text-center">
                                        <h4>No user found</h4>
                                    </div> :
                                    <Table responsive >
                                        <thead>
                                            <tr>
                                                <th >No.</th>
                                                <th>Learner name</th>
                                                <th>Email</th>
                                                <th width="200">Contact number</th>
                                                <th>Region</th>
                                                <th>Package type</th>
                                                <th>Type</th>
                                                <th>Payment type</th>
                                                <th>Account status</th>
                                                <th>Assessor</th>
                                                <th className="text-center">Progress</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map((item, i) =>
                                                <tr key={i}>
                                                    <td>{item.candidate_no}</td>
                                                    <td>{item.first_name + ' ' + item.middle_name + ' ' + item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.contact}</td>
                                                    <td>{item.regions}</td>
                                                    <td>{item.course_type}</td>
                                                    <td>{item.type_of_learner}</td>
                                                    <td>{item.payment_plan}</td>
                                                    <td>open</td>
                                                    <td>{item.accessor_name}</td>
                                                    <td>
                                                        <button className="btn btn-primary" variant="primary" style={styles.progress_buttons} block onClick={() => this.handleLearnerProgress(item)} >View</button>
                                                        <button className="btn btn-primary" variant="primary" style={styles.progress_buttons} block onClick={() => this.handleEditLearner(item)} >Edit</button>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </Table>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ minHeight: '100vh', backgroundColor: '#EEF2F6' }}>
                    <Router />
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 80 }}>
                        <img src={require('../../assets/Loader.gif')} style={{ width: 100, height: 100 }} />
                    </div>
                </div>

            )
        }
    }
}